import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/logo.svg";
import HeaderSearch from "../components/HeaderSearch";
import { MapContext, MapProvider, useAuthContext } from "../sg-react/context";
import { LogoutIcon, SettingIcon, UserTieIcon } from "../sg-react/icons";
import defaultAvatar from '../sg-react/icons/assets/user-tie.svg';
import { BubbleHead, Dropdown, HorizontalNavigation, Menu, MenuDivider, MenuItem } from "../sg-react/ui";
import './index.scss';

const NAVIGATION = [
    { key: 'map', path: '' },
    { key: 'data-management', path: 'data-management' },
    { key: 'settings', path: 'settings' },
]

const Private = () => {
    const { currentUser } = useAuthContext();
    const { t } = useTranslation();

    return (
        <MapProvider>
            <header>
                <Link to="/" id="header-logo" />
                <HeaderSearch />
                <HorizontalNavigation items={NAVIGATION} i18n />
                <div id="header-user">
                    <Dropdown
                        closeOnClick
                        dropdown={
                            <Menu>
                                <MenuItem icon={<UserTieIcon />} label={t('user:profile')} />
                                <MenuItem icon={<SettingIcon />} label={t('user:settings')} />
                                <MenuDivider />
                                <MenuItem icon={<LogoutIcon />} label={t('user:logout')} />
                            </Menu>
                        }
                    >
                        <BubbleHead image={currentUser.avatar ?? defaultAvatar} alt="Avatar" size="small" />
                    </Dropdown>
                </div>
            </header>
            <div id="content">
                <Outlet />
            </div>
        </MapProvider>
    );

}

export default Private;