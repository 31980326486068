import {
    FilterType,
    ListFilter,
    ListFilterSetting,
    ListFilters,
} from "../data/FiltersPanel";
import { Pagination } from "../models/pagination";
import { ERR_STRING } from "./constants";

export const normalize = (text: string): string =>
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const getStringValue = (obj?: any): string => {
    if (!obj) return "";
    if (obj.hasOwnProperty("toString")) return obj.toString();
    if (Array.isArray(obj)) return obj.map(getStringValue).join(",");

    try {
        return String(obj);
    } catch {
        return ERR_STRING;
    }
};

export const filtersToSearchParams = (filters: ListFilters): string => {
    const searchParams = new URLSearchParams();

    for (const key in filters) {
        const filter = filters[key];

        for (const filterKey in filter) {
            const filterForType = filter[filterKey as keyof ListFilter];
            if (Array.isArray(filterForType)) {
                filterForType.forEach((p: any) =>
                    searchParams.append(`${key}_in`, String(p))
                );
            } else if (filterForType !== undefined && filterForType !== "") {
                searchParams.append(
                    `${key}_${filterKey}`,
                    String(filterForType)
                );
            }
        }
    }
    return searchParams.toString();
};

export const paginationFromSearchParams = (
    URLSearchParams: URLSearchParams
): Partial<Pagination> => {
    const pagination: Partial<Pagination> = {};

    if (
        URLSearchParams.get("page") !== null &&
        !isNaN(Number(URLSearchParams.get("page")))
    ) {
        pagination.page = Number(URLSearchParams.get("page"));
    }
    if (
        URLSearchParams.get("perPage") !== null &&
        !isNaN(Number(URLSearchParams.get("perPage")))
    ) {
        pagination.perPage = Number(URLSearchParams.get("perPage"));
    }
    if (URLSearchParams.get("sortBy")) {
        pagination.sortBy = URLSearchParams.get("sortBy")!;
    }
    if (
        URLSearchParams.get("sortOrder") !== null &&
        !isNaN(Number(URLSearchParams.get("sortOrder")))
    ) {
        pagination.sortOrder =
            Number(URLSearchParams.get("sortOrder")) > 0 ? 1 : -1;
    }

    return pagination;
};

export const filtersFromSearchParams = (
    URLSearchParams: URLSearchParams,
    filtersSettings: ListFilterSetting[] | undefined
): ListFilters => {
    const filters: ListFilters = {};
    for (const filter of filtersSettings ?? []) {
        switch (filter.type) {
            case FilterType.In:
            case FilterType.Tree:
                const inValues = URLSearchParams.getAll(`${filter.field}_in`);
                if (!inValues?.length) continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    in: inValues,
                };
                break;
            case FilterType.Null:
                const nullValue = URLSearchParams.get(`${filter.field}_null`);

                if (nullValue !== "true" && nullValue !== "false") continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    null: nullValue === "true",
                };
                break;
            case FilterType.Search:
            case FilterType.StartsWith:
            case FilterType.Ilike:
                const searchValue = URLSearchParams.get(
                    `${filter.field}_${filter.type}`
                );
                if (!searchValue) continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    [filter.type]: searchValue,
                };
                break;
            default:
        }
    }
    return filters;
};
