import { SimpleListSchemaColumn } from "../sg-react/data/SimpleList";
import { FormValidation, ValidationRule } from "../sg-react/hooks/useForm";
import { PgBase } from "../sg-react/models";
import { Company } from "./company";

export type Dependency = PgBase & {
    name: string;
    description?: string;
    streetNumber?: string;
    route?: string;
    city?: string;
    region?: string;
    country?: string;
    postalCode?: string;
    address?: string;
    lat: number;
    lng: number;
    additionalData?: Record<string, any>;
    directoryId: number;
    directory: Company;
};

export const DEPENDENCY_VALIDATION: FormValidation = {
    name: [{ rule: ValidationRule.Required }],
    lat: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
    lng: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
};

export const DEPENDENCY_SIMPLE_LIST_SCHEMA: SimpleListSchemaColumn<Dependency>[] =
    [
        {
            field: "name",
            label: "Nom",
        },
        {
            field: "additionalData.modele",
            label: "Modèle",
        },
        {
            field: "additionalData.constructorName",
            label: "Constructeur",
        },
        {
            field: "additionalData.puissance",
            label: "Puissance nominale",
        },
        {
            field: "additionalData.hauteur_max_mat",
            label: "Hauteur",
        },
    ];
