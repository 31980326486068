import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SearchResultItem } from "../data/Search";
import { TreeNode } from "../data/Tree";
import { Geoname } from "../models/geo";
import '../styles/index.scss';

export interface MapFilters {
    mode: 'sourcing' | 'internal';
    fetch?: boolean;
    keyword?: string;
    companies?: number[];
    geonames?: SearchResultItem<Geoname>[];
    qualifiers?: (string | number)[];
}

interface MapProviderProps {
    children: ReactNode;
    withSourcing?: boolean;
}

export type MapContextType = {
    filters: MapFilters;
    withSourcing: boolean;
    setFilters: (filters: MapFilters) => void;
};

export const MapContext = createContext<MapContextType>({
    filters: { mode: 'internal' },
    withSourcing: false,
    setFilters: (_) => null,
});

const MapProvider = ({ children, withSourcing }: MapProviderProps) => {
    const [filters, setFilters] = useState<MapFilters>({ mode: 'internal', fetch: false });
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleChangeFilters = useCallback((filters: MapFilters) => {
        const searchParams = new URLSearchParams();

        searchParams.append('mode', filters.mode);

        for (const geoname of filters.geonames ?? []) {
            searchParams.append('geonames', JSON.stringify({
                key: geoname.key,
                label: geoname.label,
                radius: geoname.radius
            }));
        }
        for (const qualifier of filters.qualifiers ?? []) {
            searchParams.append('qualifiers', String(qualifier));
        }
        if (!!filters.keyword) {
            searchParams.append('keyword', filters.keyword);
        }
        for (const id of filters.companies ?? []) {
            searchParams.append('companies', String(id));
        }

        /*         try {
                    let storedSearch = JSON.parse(localStorage.getItem('sg-map-search') ?? '[]');
                    if (Array.isArray(storedSearch)) {
                        if (storedSearch.length > 3) {
                            storedSearch.splice(-1);
                        }
                    } else {
                        storedSearch = [];
                    }
                    localStorage.setItem('sg-map-search', JSON.stringify([searchParams.toString(), ...storedSearch]));
                } catch { } */
        setFilters(filters);
        navigate(`/?${searchParams.toString()}`, { replace: location.pathname === '/' });
    }, [location]);

    const filtersFromQueryParams = useCallback((URLSearchParams: URLSearchParams) => {
        const filters: MapFilters = { mode: 'internal', fetch: true };

        const mode = URLSearchParams.get('mode');
        if (mode !== 'sourcing') {
            filters.mode = 'internal';
        }
        const keyword = URLSearchParams.get('keyword');
        if (!!keyword) {
            filters.keyword = keyword;
        }
        const qualifiers = URLSearchParams.getAll('qualifiers');
        for (const qualifier of qualifiers) {
            if (!isNaN(Number(qualifier))) {
                filters.qualifiers = [...(filters.qualifiers ?? []), Number(qualifier)];
            }
        }
        const geonames = URLSearchParams.getAll('geonames');
        for (const geoname of geonames) {
            try {
                const geonameParsed = JSON.parse(geoname);

                if (geonameParsed.key) {
                    filters.geonames = [...(filters.geonames ?? []), {
                        key: geonameParsed.key,
                        label: geonameParsed.label ?? '',
                        radius: !!geonameParsed.radius && !isNaN(Number(geonameParsed.radius)) ? Number(geonameParsed.radius) : 0
                    }];
                }
            } catch {
                continue;
            }
        }

        const companies = URLSearchParams.getAll('companies');
        if (!!companies?.length) {
            filters.companies = companies.filter(c => !isNaN(Number(c))).map(c => Number(c));
        }
        return filters;
    }, []);

    useEffect(() => {
        if (location.pathname === '/' && !filters.fetch) {
            setFilters(filtersFromQueryParams(searchParams));
        }
    }, [location.pathname]);

    return (
        <MapContext.Provider value={{
            filters,
            setFilters: handleChangeFilters,
            withSourcing: !!withSourcing
        }}>
            {children}
        </MapContext.Provider>
    );
};

const useMapContext = () => {
    return useContext(MapContext);
};

export { MapProvider, useMapContext };
export default MapContext;