import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, TextField } from '../../form';
import { useRequest } from '../../hooks';
import { AddIcon, DownIcon, FloppyIcon, PencilIcon, ReloadIcon, RightIcon, TrashIcon } from '../../icons';
import { Button } from '../../ui';
import { toggleInArray } from '../../utils/objects';
import './index.scss';

interface TreeAddProps {
    parent?: string | number;
    level?: number;
    onAdd: (node: Partial<TreeNode>, parent?: string | number) => void;
}

const TreeAdd = ({ parent, level, onAdd }: TreeAddProps) => {
    const { t } = useTranslation();
    const [dataToEdit, setDataToEdit] = useState<Partial<TreeNode> | null>(null);

    const handleSave = useCallback((data: Partial<TreeNode>, parent?: string | number) => {
        onAdd({ ...data, parent });
        setDataToEdit(null);
    }, [onAdd]);

    return (
        <div className="tree-node tree-add" style={level ? { paddingLeft: (12 + level * 30) + 'px' } : undefined}>
            <AddIcon onClick={() => setDataToEdit({})} />
            {!!dataToEdit ? (
                <div className="tree-node-edit">
                    <TextField small id="name" label i18n="fields" value={dataToEdit.name} onChange={(v) => setDataToEdit({ ...dataToEdit, name: v })} />
                    <TextField small id="description" label i18n="fields" value={dataToEdit.description} onChange={(v) => setDataToEdit({ ...dataToEdit, description: v })} />
                    <Button color="warning" icon={<ReloadIcon />} onClick={() => setDataToEdit(null)} />
                    <Button color="success" icon={<FloppyIcon />} onClick={() => handleSave(dataToEdit, parent)} />
                </div>
            ) : <div onClick={() => setDataToEdit({})} ><span>{t('actions:add')}</span></div>}
        </div>
    );
}


export interface TreeNode {
    key: string | number;
    name: string;
    description?: string;
    parent?: string | number;
    children?: TreeNode[];
    selected?: boolean;
    childrenSelected?: boolean;
}

interface NodeProps {
    data: TreeNode;
    level?: number;
    onSelect?: (d: (string | number)[]) => void;
    selected?: (number | string)[];
    isParentSelected?: boolean;
    allowParentSelect?: boolean;
    onAdd?: (node: Partial<TreeNode>) => void;
    onEdit?: (node: Partial<TreeNode>) => void;
}

const getIdsRecursive = (node: TreeNode): (string | number)[] => [node.key, ...(node.children?.map(c => getIdsRecursive(c)).flat() ?? [])];

const Node = ({ data, onSelect, selected, onAdd, onEdit, level, allowParentSelect, isParentSelected }: NodeProps) => {
    const [isOpened, setOpened] = useState<boolean>(false);
    const [dataToEdit, setDataToEdit] = useState<Partial<TreeNode> | null>(null);

    const canSelect = useMemo(() => !!onSelect && (!!allowParentSelect || !data.children?.length), [data, onSelect, allowParentSelect]);
    const isSelected = useMemo(() => !!selected?.includes(data.key), [data, selected]);
    const hasChildrenSelected = useMemo(() => !!data.children?.some(c => selected?.includes(c.key)), [data, selected]);

    const handleSelect = useCallback((node: TreeNode) => {
        if (!onSelect) return;

        onSelect(toggleInArray((selected ?? []), node.key, (n1, n2) => n1 === n2));
    }, [selected, onSelect]);

    const handleEdit = useCallback((data: TreeNode) => {
        setDataToEdit({ ...data });
    }, []);

    const handleSave = useCallback((data: Partial<TreeNode>) => {
        if (onEdit) {
            onEdit(data);
        }
        setDataToEdit(null);
    }, [onEdit]);

    return (
        <Fragment>
            <div className="tree-node" style={level ? { paddingLeft: (12 + level * 30) + 'px' } : undefined}>
                {!!isOpened && (!!data.children?.length || !!onAdd) && <DownIcon onClick={() => setOpened(false)} />}
                {!isOpened && (!!data.children?.length || !!onAdd) && <RightIcon onClick={() => setOpened(true)} />}
                {canSelect && <Checkbox disabled={isParentSelected} id={String(data.key)} value={isSelected} onChange={() => handleSelect(data)} />}
                {!!dataToEdit ? (
                    <div className="tree-node-edit">
                        <TextField small id="name" label i18n="fields" value={dataToEdit.name} onChange={(v) => setDataToEdit({ ...dataToEdit, name: v })} />
                        <TextField small id="description" label i18n="fields" value={dataToEdit.description} onChange={(v) => setDataToEdit({ ...dataToEdit, description: v })} />
                        <Button color="warning" icon={<ReloadIcon />} onClick={() => setDataToEdit(null)} />
                        <Button color="success" icon={<FloppyIcon />} onClick={() => handleSave(dataToEdit)} />
                    </div>
                ) : (
                    <div onClick={() => canSelect ? handleSelect(data) : setOpened(!isOpened)} className={isSelected ? 'tree-selected' : hasChildrenSelected ? 'tree-child-selected' : ''}>
                        <span className="tree-name">{data.name ?? data.key}</span>
                        <span className="tree-description">{data.description}</span>
                    </div>
                )}
                {!!onEdit && !dataToEdit && (
                    <Fragment>
                        <Button color="error" icon={<TrashIcon />} />
                        <Button color="secondary" icon={<PencilIcon />} onClick={() => handleEdit(data)} />
                    </Fragment>
                )}
            </div>
            {isOpened && (!!data.children?.length || !!onAdd) && (
                <Fragment>
                    {data.children?.map(node => (
                        <Node
                            key={node.key}
                            level={(level ?? 0) + 1}
                            data={node}
                            isParentSelected={isSelected}
                            onSelect={onSelect}
                            selected={selected}
                            onEdit={onEdit}
                            onAdd={onAdd}
                        />
                    ))}
                    {!!onAdd && <TreeAdd parent={data.key} level={(level ?? 0) + 1} onAdd={onAdd} />}
                </Fragment>
            )}
        </Fragment>
    )
}

interface TreeProps<T> {
    data?: TreeNode[];
    endpoint?: string;
    crudEndpoint?: string;
    mapper?: (d: T) => TreeNode;
    reverseMapper?: (d: Partial<TreeNode>) => Partial<T>;
    onSelect?: (d: (string | number)[]) => void;
    selected?: (number | string)[];
    allowParentSelect?: boolean;
    withRoot?: boolean;
    add?: boolean;
    edit?: boolean;
}

const Tree = <T,>({
    data: dataFromProps,
    endpoint,
    crudEndpoint,
    mapper,
    reverseMapper,
    withRoot,
    add,
    edit,
    onSelect,
    selected,
    allowParentSelect
}: TreeProps<T>) => {
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TreeNode[]>([]);

    const map = useCallback((dataFromApi: T[]): TreeNode[] => {
        if (!mapper) return dataFromApi as TreeNode[];

        try {
            return dataFromApi.map(d => mapper(d));
        } catch (e) {
            return [];
        }
    }, [mapper]);

    const getData = useCallback(() => {
        if (!endpoint) {
            setData(dataFromProps ?? []);
            return;
        }
        if (isLoading) return;

        setLoading(true);

        request.get<T[]>(endpoint, { i18n: true, errorMessage: 'error.server_error' })
            .then((e) => setData(map(e)))
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [endpoint, dataFromProps]);

    const handleCreateOrEdit = useCallback((node: Partial<TreeNode>) => {
        if (!crudEndpoint || isLoading) return;

        setLoading(true);

        const requestMethod = node.key ? request.put : request.post;

        requestMethod(crudEndpoint, reverseMapper ? reverseMapper(node) : node, { i18n: true, errorMessage: 'error.server_error', successMessage: node.key ? 'success.update' : 'success.create' })
            .then(() => getData())
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [crudEndpoint, isLoading, reverseMapper]);

    useEffect(() => {
        getData();
    }, [endpoint, dataFromProps]);

    return (
        <div className="tree">
            {(withRoot || !data?.length ? data : data[0].children)?.map(node => (
                <Node
                    key={node.key}
                    data={node}
                    onAdd={add ? (node) => handleCreateOrEdit(node) : undefined}
                    onEdit={edit ? (node) => handleCreateOrEdit(node) : undefined}
                    onSelect={onSelect}
                    selected={selected}
                    allowParentSelect={allowParentSelect}
                />
            ))}
            {!!add && <TreeAdd parent={withRoot || !data?.length ? undefined : data[0].key} onAdd={handleCreateOrEdit} />}
        </div>
    )
}

export default Tree;