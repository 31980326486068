import { Company } from "./company";
import { PgBase } from "../sg-react/models";
import { FormValidation, ValidationRule } from "../sg-react/hooks/useForm";
import { SimpleListSchemaColumn } from "../sg-react/data/SimpleList";

export type Contact = PgBase & {
    firstName: string;
    lastName: string;
    position: string;
    service: ContactService;
    phone?: string;
    email?: string;
    internal: boolean;
    directory?: Company;
};

export enum ContactService {
    Marketing = "MARKETING",
    Quality = "QUALITY",
    Customer = "CUSTOMER",
    ExecutiveManagement = "EXECUTIVE_MANAGEMENT",
}

export const ContactServiceLabel = {
    [ContactService.Marketing]: "Commercial",
    [ContactService.Quality]: "Qualité",
    [ContactService.Customer]: "Clientèle",
    [ContactService.ExecutiveManagement]: "Direction générale",
};

export const ContactServices = [
    {
        key: ContactService.Marketing,
        label: ContactServiceLabel[ContactService.Marketing],
    },
    {
        key: ContactService.Quality,
        label: ContactServiceLabel[ContactService.Quality],
    },
    {
        key: ContactService.Customer,
        label: ContactServiceLabel[ContactService.Customer],
    },
    {
        key: ContactService.ExecutiveManagement,
        label: ContactServiceLabel[ContactService.ExecutiveManagement],
    },
];

export const CONTACT_VALIDATION: FormValidation = {
    firstName: [{ rule: ValidationRule.Required }],
    lastName: [{ rule: ValidationRule.Required }],
    position: [{ rule: ValidationRule.Required }],
    service: [{ rule: ValidationRule.Required }],
    email: [{ rule: ValidationRule.Email }],
};

export const CONTACT_SIMPLE_LIST_SCHEMA: SimpleListSchemaColumn<Contact>[] = [
    { field: "firstName" },
    { field: "lastName" },
    { field: "position" },
    { field: "service", display: (e) => ContactServiceLabel[e.service] },
    { field: "phone" },
    { field: "email" },
    { field: "internal", display: (e) => (e.internal ? "Interne" : "") },
];
