// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pie {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--theme-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: black;
  flex-shrink: 0;
}
.pie > .pie-center {
  width: 60%;
  height: 60%;
  background-color: var(--theme-color-white);
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}
.pie > .pie-total {
  z-index: 2;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/Pie/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AACJ;AACI;EACI,UAAA;EACA,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;AACR;AACI;EACI,UAAA;EACA,eAAA;AACR","sourcesContent":[".pie {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: var(--theme-color-white);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    color: black;\n    flex-shrink: 0;\n\n    & > .pie-center {\n        width: 60%;\n        height: 60%;\n        background-color: var(--theme-color-white);\n        border-radius: 50%;\n        position: absolute;\n        z-index: 1;\n    }\n    & > .pie-total {\n        z-index: 2;\n        font-size: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
