// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 10;
  background-color: var(--theme-color-white);
  border-left: var(--theme-color-grey-medium) 1px solid;
  width: 360px;
}
.modal-right > .modal-right-close-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 18px;
  height: 18px;
  fill: var(--theme-color-grey-light);
  cursor: pointer;
}

.theme-dark-blue .modal-right {
  background-color: var(--theme-color-grey-darkest);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ModalRight/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,0CAAA;EACA,qDAAA;EACA,YAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;EACA,eAAA;AACR;;AAII;EACI,iDAAA;AADR","sourcesContent":[".modal-right {\n    position: fixed;\n    right: 0;\n    top: 0;\n    height: 100%;\n    z-index: 10;\n    background-color: var(--theme-color-white);\n    border-left: var(--theme-color-grey-medium) 1px solid;\n    width: 360px;\n\n    & > .modal-right-close-icon {\n        position: absolute;\n        right: 12px;\n        top: 12px;\n        width: 18px;\n        height: 18px;\n        fill: var(--theme-color-grey-light);\n        cursor: pointer;\n    }\n}\n\n.theme-dark-blue {\n    .modal-right {\n        background-color: var(--theme-color-grey-darkest);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
