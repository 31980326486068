// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dependencies-form {
  display: flex;
  flex-direction: row;
  min-height: 400px;
}
.dependencies-form > .simple-list {
  width: 60%;
  height: 100%;
}
.dependencies-form > .map {
  width: 40%;
  height: 100%;
  border-right: var(--theme-data-border) 1px solid;
  border-top: var(--theme-data-border) 1px solid;
  border-bottom: var(--theme-data-border) 1px solid;
}
.dependencies-form > .map .marker-dependency {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--theme-color-accent3);
}
.dependencies-form > .map .marker-dependency > svg {
  width: 18px;
  height: 18px;
  fill: var(--theme-color-base);
}`, "",{"version":3,"sources":["webpack://./src/components/DependenciesForm/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACI;EACI,UAAA;EACA,YAAA;AACR;AACI;EACI,UAAA;EACA,YAAA;EAEA,gDAAA;EACA,8CAAA;EACA,iDAAA;AAAR;AAEQ;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;AADZ;AAGY;EACI,WAAA;EACA,YAAA;EACA,6BAAA;AADhB","sourcesContent":[".dependencies-form {\n    display: flex;\n    flex-direction: row;\n    min-height: 400px;\n\n    & > .simple-list {\n        width: 60%;\n        height: 100%;\n    }\n    & > .map {\n        width: 40%;\n        height: 100%;\n\n        border-right: var(--theme-data-border) 1px solid;\n        border-top: var(--theme-data-border) 1px solid;\n        border-bottom: var(--theme-data-border) 1px solid;\n\n        & .marker-dependency {\n            content: \"\";\n            display: flex;\n            align-items: center;\n            justify-content: center;\n\n            width: 28px;\n            height: 28px;\n            border-radius: 50%;\n            background-color: var(--theme-color-accent3);\n\n            & > svg {\n                width: 18px;\n                height: 18px;\n                fill: var(--theme-color-base);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
