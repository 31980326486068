import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { COMPANY_SIMPLE_LIST_SCHEMA, COMPANY_VALIDATION, Company } from "../../models/company";
import { EntitySearch, SimpleList } from "../../sg-react/data";
import Tree from "../../sg-react/data/Tree";
import { DatePicker, LocationPicker, TextArea, TextField } from "../../sg-react/form";
import ImageField from "../../sg-react/form/ImageField";
import { useForm, useRequest } from "../../sg-react/hooks";
import { AddIcon, FormIcon, PositionIcon, TrashIcon, TreeIcon, TypingIcon, UsersIcon, WindTurbine, WindTurbines } from "../../sg-react/icons";
import LayoutLeftTabs, { LayoutLeftTabsItem, LayoutLeftTabsSection } from "../../sg-react/ui/LayoutLeftTabs";
import PageHeader from "../../sg-react/ui/PageHeader";
import DependenciesForm from "../DependenciesForm";
import './index.scss';
import { CONTACT_SIMPLE_LIST_SCHEMA, Contact } from "../../models/contact";
import ContactForm from "../ContactForm";
import { addOrReplaceInArray } from "../../sg-react/utils/objects";

const ITEMS = [
    { key: 'details', label: 'Informations', icon: <FormIcon /> },
    { key: 'description', label: 'Description', icon: <TypingIcon /> },
    { key: 'location', label: 'Localisation', icon: <PositionIcon /> },
    { key: 'contacts', label: 'Contacts', icon: <UsersIcon /> },
    { key: 'qualifiers', label: 'Secteurs', icon: <TreeIcon /> },
];

const ITEMS_WIND = [
    { key: 'additional_data', label: 'Détail du parc', icon: <WindTurbines /> },
    { key: 'turbines', label: 'Eoliennes', icon: <WindTurbine /> },
];

const CompanyForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const { entity, validate, setEntity, attachInput, hasChanged, onMultipleChange, onChange } = useForm<Company>({});
    const request = useRequest();
    const [isParentModalVisible, setParentModalVisible] = useState<boolean>(false);
    const [contactIndexToEdit, setContactIndexToEdit] = useState<number | null>(null);
    const [layoutItems, setLayoutItems] = useState<LayoutLeftTabsItem[]>(ITEMS);

    const createOrUpdate = useCallback(async (entity: Partial<Company>) => {
        if (!validate(COMPANY_VALIDATION)) return;

        const isNew = !entity.id;

        const requestMethod = isNew ? request.post : request.put;
        const successMessage = isNew ? 'success.create' : 'success.update';

        requestMethod<Company>('/directory', entity, {
            i18n: true,
            loader: true,
            successMessage,
            errorMessage: 'error.server_error'
        })
            .then(() => navigate(-1))
            .catch(() => null);
    }, [validate]);

    const qualifiers: number[] | undefined = useMemo(() => entity.qualifiers?.map(q => q.id), [entity]);
    const handleQualifiersChange = useCallback((keys: (string | number)[]) => {
        onChange('qualifiers', keys.map(key => ({ id: key, name: String(key) })));
    }, [onChange]);

    const handleParentChange = useCallback((parents: Company[]) => {
        if (!parents.length) return;
        onMultipleChange({
            parentId: parents[0].id,
            parent: parents[0]
        });
    }, [onMultipleChange])

    const handleSubmitContact = useCallback((c: Partial<Contact>) => {
        if (contactIndexToEdit === null) return;
        const contacts = [...(entity.contacts ?? [])];

        if (contactIndexToEdit === -1) {
            contacts.push(c as Contact);
        } else {
            contacts[contactIndexToEdit] = c as Contact;
        }
        onChange('contacts', contacts);
    }, [entity, onChange, contactIndexToEdit]);

    useEffect(() => {
        if (entity.qualifiers?.some(q => q.id === 1807)) {
            setLayoutItems([...ITEMS, ...ITEMS_WIND]);
        } else {
            setLayoutItems(ITEMS);
        }
    }, [entity.qualifiers]);

    useEffect(() => {
        if (id === 'create') {
            setEntity({})
        } else if (id) {
            request.get<Company>(`/directory/${id}`, {
                i18n: true,
                loader: true,
                errorMessage: 'error.server_error'
            })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else {
            navigate(-1);
        }
    }, []);

    return (
        <div id="company-form">
            <PageHeader
                breadcrumbs={[
                    { href: '/data-management', label: 'path:data-management', i18n: true },
                    { label: entity.id ? entity.name ?? '' : t('actions:create') }
                ]}
                actions={!hasChanged ? [
                    { label: 'actions:close', i18n: true, color: 'secondary', onClick: () => navigate(-1) },
                ] : [
                    { label: 'actions:close', i18n: true, color: 'secondary', onClick: () => navigate(-1) },
                    { label: 'actions:save', i18n: true, color: 'primary', onClick: () => createOrUpdate(entity) },
                ]}
            />
            <LayoutLeftTabs items={layoutItems}>
                <LayoutLeftTabsSection item={layoutItems[0]}>
                    <TextField small label={t('companies:name')} {...attachInput('name')} />
                    <TextField small label={t('companies:website')} {...attachInput('website')} />
                    <ImageField small label={t('companies:logo')} endpoint="/directory/logo" {...attachInput('logo')} />
                    <TextField id="parent" small disabled value={entity.parent?.name} label={t('companies:parent')} onChange={console.log} onFocus={() => setParentModalVisible(true)} />
                </LayoutLeftTabsSection>
                <LayoutLeftTabsSection item={layoutItems[1]}>
                    <TextArea small rows={5} {...attachInput('description')} />
                </LayoutLeftTabsSection>
                <LayoutLeftTabsSection item={layoutItems[2]}>
                    <LocationPicker entity={entity} onMultipleChange={onMultipleChange} small />
                </LayoutLeftTabsSection>
                <LayoutLeftTabsSection item={layoutItems[3]}>
                    <SimpleList<Contact>
                        data={entity.contacts}
                        primaryKey={['id', 'firstName', 'lastName']}
                        i18n="contacts"
                        schema={CONTACT_SIMPLE_LIST_SCHEMA}
                        actions={{
                            add: () => setContactIndexToEdit(-1),
                            edit: (_, index) => setContactIndexToEdit(index),
                        }}
                    />
                </LayoutLeftTabsSection>
                <LayoutLeftTabsSection item={layoutItems[4]}>
                    <Tree endpoint="/qualifiers/tree/1800" onSelect={handleQualifiersChange} selected={qualifiers} />
                </LayoutLeftTabsSection>
                {layoutItems.length > 5 && (
                    <LayoutLeftTabsSection item={layoutItems[5]}>
                        <div className="row">
                            <TextField small className="col-lg-50" type="number" label="Altitude du parc" {...attachInput('additionalData.elevation')} />
                            <TextField small className="col-lg-50" type="number" label="Nombre de turbines" {...attachInput('additionalData.turbinesCount')} />
                        </div>
                        <div className="row">
                            <DatePicker small className="col-lg-50" label="Mise en exploitation" {...attachInput('additionalData.exploitationDate')} />
                            <TextField small className="col-lg-50" label="Référence de turbine" {...attachInput('additionalData.turbinesReference')} />
                        </div>
                        <div className="row">
                            <DatePicker small className="col-lg-50" label="Date repowering" {...attachInput('additionalData.repoweringDate')} />
                            <TextField small className="col-lg-50" label="Référence de turbine pour repowering" {...attachInput('additionalData.repoweringTurbinesReference')} />
                        </div>
                    </LayoutLeftTabsSection>
                )}
                {layoutItems.length > 6 && (
                    <LayoutLeftTabsSection item={layoutItems[6]}>
                        <DependenciesForm directory={entity} onChange={(d) => onChange('dependencies', d)} />
                    </LayoutLeftTabsSection>
                )}
            </LayoutLeftTabs>
            {contactIndexToEdit !== null && <ContactForm contact={contactIndexToEdit === -1 ? {} : entity.contacts?.[contactIndexToEdit] ?? {}} onSubmit={handleSubmitContact} onClose={() => setContactIndexToEdit(null)} />}
            <EntitySearch<Company>
                visible={isParentModalVisible}
                endpoint="/directory"
                primaryKey="id"
                schema={COMPANY_SIMPLE_LIST_SCHEMA}
                onSubmit={handleParentChange}
                onClose={() => setParentModalVisible(false)}
            />
        </div>
    )
}

export default CompanyForm;