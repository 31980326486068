import { DataListColumn } from "../sg-react/data/DataList";
import { SimpleListSchemaColumn } from "../sg-react/data/SimpleList";
import { FormValidation, ValidationRule } from "../sg-react/hooks/useForm";
import { PgBase } from "../sg-react/models";
import { isType } from "../sg-react/utils/objects";
import { Contact } from "./contact";
import { Dependency } from "./dependency";
import { Qualifier } from "./qualifier";

export type Company = PgBase & {
    name: string;
    logo?: string;
    description?: string;
    streetNumber?: string;
    route?: string;
    city: string;
    region?: string;
    country: string;
    postalCode?: string;
    address?: string;
    localPhone?: string;
    internationalPhone?: string;
    website?: string;
    additionalData?: Record<string, any>;
    lat: number;
    lng: number;
    parentId?: number;
    parent?: Company;
    qualifiers?: Qualifier[];
    dependencies?: Dependency[];
    contacts?: Contact[];
};

export const COMPANY_VALIDATION: FormValidation = {
    name: [{ rule: ValidationRule.Required }],
};

export const COMPANY_LIST_SCHEMA: DataListColumn<Company>[] = [
    {
        field: "id",
        label: "ID",
        width: "5%",
        i18n: "companies:id",
        options: {
            order: true,
        },
    },
    {
        field: "name",
        label: "Name",
        display: "textinput",
        i18n: "companies:name",
        width: "20%",
        options: {
            order: true,
            search: true,
            edit: true,
        },
    },
    {
        field: "city",
        label: "City",
        width: "15%",
        i18n: "companies:city",
        options: {
            order: true,
            ilike: true,
        },
    },
    {
        field: "region",
        label: "Region",
        display: "textinput",
        i18n: "companies:region",
        width: "15%",
        options: {
            order: true,
            ilike: true,
            null: true,
        },
    },
    {
        field: "country",
        label: "Country",
        display: "textinput",
        i18n: "companies:country",
        width: "15%",
        options: {
            order: true,
            ilike: true,
            null: true,
        },
    },
    {
        field: "sector",
        label: "Sector",
        i18n: "companies:sector",
        display: (e) =>
            e.qualifiers
                ?.filter((q) => q.rootId === 1800)
                .map((q) => q.name)
                .join(", "),
        width: "20%",
        options: {
            order: true,
            null: true,
            search: true,
        },
    },
];

export const COMPANY_SIMPLE_LIST_SCHEMA: SimpleListSchemaColumn<Company>[] = [
    {
        field: "name",
        label: "Nom",
    },
    {
        field: "address",
        label: "Adresse",
    },
];

export const isCompany = (c: any): c is Company =>
    isType<Company>(c, (o) => o.hasOwnProperty("city"));
