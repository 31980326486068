import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../hooks';
import { BubbleHead } from '../../ui';
import ImageUploader from '../ImageUploader';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

interface ImageFieldProps extends FormPropsInterface<string> {
    endpoint: string,
    className?: string,
}

const ImageField = ({
    value,
    endpoint,
    disabled,
    onChange,
    ...rest
}: ImageFieldProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const request = useRequest();

    const handleChange = async (file: File) => {
        if (isLoading) return;

        const data = new FormData();
        data.append('image', file);

        setLoading(true);
        request.post<string>(endpoint, data, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .then(onChange)
            .catch(() => null)
            .finally(() => setLoading(false));
    }

    return (
        <InputWrapper type="image" {...rest}>
            {!!value && <BubbleHead image={value} size="small" />}
            <div className="input-actions">
                {!!value && <span onClick={() => onChange(undefined)}>{t('actions:delete')}</span>}
                <ImageUploader onChange={handleChange}>{t('actions:update')}</ImageUploader>
            </div>
        </InputWrapper>
    );
}

export default ImageField;