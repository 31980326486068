import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CheckboxList, Switch, TextField } from '../../form';
import { filtersFromSearchParams, filtersToSearchParams } from '../../utils/format';
import './index.scss';
import { FiltersHorizontalIcon } from '../../icons';
import { useDebounce } from '../../hooks';
import Tree from '../Tree';

export const DEFAULT_FILTERS = {};
export enum FilterType {
    Search = 'search',
    StartsWith = 'startsWith',
    Ilike = 'ilike',
    Equals = 'equals',
    In = 'in',
    Tree = 'tree',
    Null = 'null',
}

export type ListFilter = {
    search?: string;
    startsWith?: string;
    ilike?: string;
    equals?: string | number | boolean;
    in?: (string | number | boolean)[];
    from?: Date;
    to?: Date;
    null?: boolean;
}
export type ListFilters = {
    [key: string]: ListFilter;
}

export interface ListFilterSetting {
    field: string;
    label?: string;
    type: FilterType;
    items?: { key: string | boolean | number, label: string }[];
    endpoint?: string;
}

interface FiltersPanelProps {
    filtersSettings?: ListFilterSetting[]
}

const FiltersPanel = ({ filtersSettings }: FiltersPanelProps) => {
    const [filters, setFilters] = useState<ListFilters>({ ...DEFAULT_FILTERS });
    const [isInit, setInit] = useState<boolean>(false);
    const { t } = useTranslation();
    const location = useLocation();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();
    useDebounce(() => {
        if (isInit) {
            setURLSearchParams(filtersToSearchParams(filters), { replace: true });
        }
    }, 600, [filters]);

    const handleFilterChange = useCallback((filters: ListFilters, field: string, value: Partial<ListFilter>) => {
        const _filters = { ...filters, [field]: { ...filters?.[field], ...value } }
        setFilters(_filters);
        if (!isInit) {
            setInit(true);
        }
    }, [isInit]);

    const handleChange = useCallback((filters: ListFilters) => {
        setURLSearchParams(filtersToSearchParams(filters), { replace: true });
    }, []);

    useEffect(() => {
        setInit(false);
        setFilters(filtersFromSearchParams(URLSearchParams, filtersSettings));
    }, [location.pathname]);

    return (
        <div className="filters-panel">
            <div className="filters-panel-header">
                <FiltersHorizontalIcon />
                <span>{t('filters:filters')}</span>
            </div>
            {filtersSettings?.map(f => {
                const filter: ListFilter | undefined = filters?.[f.field];

                switch (f.type) {
                    case FilterType.In:
                        return (
                            <div
                                className={`filters-panel-item ${!!filter?.in?.length ? 'active' : ''}`}
                                key={`${f.field}_in`}
                            >
                                {!!f.label && <h4>{f.label}</h4>}
                                <CheckboxList<(string | number | boolean)[]>
                                    id={f.field}
                                    value={filter?.in}
                                    items={f.items?.map(i => ({ key: String(i.key), label: i.label })) ?? []}
                                    onChange={(values) => handleFilterChange(filters, f.field, { in: values })}
                                />
                            </div>
                        );
                    case FilterType.Tree:
                        return (
                            <div
                                className={`filters-panel-item ${!!filter?.in?.length ? 'active' : ''}`}
                                key={`${f.field}_in`}
                            >
                                {!!f.label && <h4>{f.label}</h4>}
                                <Tree
                                    allowParentSelect
                                    endpoint={f.endpoint ?? ''}
                                    selected={filter?.in?.map(v => !isNaN(Number(v)) ? Number(v) : String(v))}
                                    onSelect={(values) => handleFilterChange(filters, f.field, { in: values })}
                                />
                            </div>
                        );
                    case FilterType.Null:
                        return (
                            <div
                                className={`filters-panel-item ${filter?.null !== undefined ? 'active' : ''}`}
                                key={`${f.field}_null`}
                            >
                                {!!f.label && <h4>{f.label}</h4>}
                                <h5><small>{t('filters:show_empty')}</small></h5>
                                <Switch<boolean | undefined>
                                    small
                                    id={f.field}
                                    items={[{ key: true, label: t('filters:yes') }, { key: false, label: t('filters:no') }, { key: undefined, label: t('filters:all') }]}
                                    value={filter?.null}
                                    onChange={(value) => handleFilterChange(filters, f.field, { null: value })}
                                />
                            </div>
                        );
                    case FilterType.Search:
                    case FilterType.StartsWith:
                    case FilterType.Ilike:
                        return (
                            <div
                                className={`filters-panel-item ${!!filter?.[f.type] ? 'active' : ''}`}
                                key={`${f.field}_search`}
                            >
                                {!!f.label && <h4>{f.label}</h4>}
                                <TextField
                                    small
                                    id={f.field}
                                    value={filter?.[f.type]}
                                    onChange={(value) => handleFilterChange(filters, f.field, { [f.type]: value })}
                                />
                            </div>
                        );
                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default FiltersPanel;