import { PgBase } from "../sg-react/models";
import { isType } from "../sg-react/utils/objects";

export type Qualifier = PgBase & {
    name: string;
    description?: string;
    parent?: Qualifier;
    parentId?: number;
    root?: Qualifier;
    rootId?: number;
};

export interface QualifierTree {
    qualifier: Qualifier;
    children?: QualifierTree[];
}

export const isQualifier = (q: any): q is Qualifier =>
    isType<Qualifier>(q, (o) => o.hasOwnProperty("rootId"));
