// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-picker .location-picker-location {
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
}
.location-picker .location-picker-location > * {
  flex-grow: 1;
}
.location-picker .location-picker-modal > .map {
  height: 400px;
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/LocationPicker/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,aAAA;EACA,YAAA;AAFR","sourcesContent":[".location-picker {\n    & .location-picker-location {\n        display: flex;\n        flex-direction: row;\n        gap: 4px;\n        width: 100%;\n\n        & > * {\n            flex-grow: 1;\n        }\n    }\n\n    & .location-picker-modal > .map {\n        height: 400px;\n        width: 400px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
