import { useCallback } from "react";
import { Company } from "../../models/company";
import { CONTACT_VALIDATION, Contact, ContactServices } from "../../models/contact";
import { useForm, useRequest } from "../../sg-react/hooks";
import { Checkbox, Select, TextField } from "../../sg-react/form";
import { useTranslation } from "react-i18next";
import { Modal } from "../../sg-react/ui";

interface ContactFormProps {
    contact: Partial<Contact>;
    onSubmit: (e: Contact) => void;
    onClose: () => void;
}

const ContactForm = ({ contact, onSubmit, onClose }: ContactFormProps) => {
    const { t } = useTranslation();
    const { entity, reset, attachInput, validate, onMultipleChange } = useForm<Contact>(contact);

    const handleSubmit = useCallback(async () => {
        if (!validate(CONTACT_VALIDATION)) {
            return;
        }

        onSubmit(entity as Contact);
        onClose();
    }, [validate, entity]);

    return (
        <Modal
            type="small"
            header={entity.id
                ? t('data:edit')
                : t('data:new')
            }
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <div className="row">
                <TextField small label i18n="contacts" {...attachInput('firstName')} className="col-lg-50" />
                <TextField small label i18n="contacts" {...attachInput('lastName')} className="col-lg-50" />
            </div>
            <div className="row">
                <TextField small label i18n="contacts" {...attachInput('position')} className="col-lg-50" />
                <Select small label items={ContactServices} i18n="contacts" {...attachInput('service')} className="col-lg-50" />
            </div>
            <div className="row">
                <TextField small label i18n="contacts" {...attachInput('phone')} className="col-lg-50" />
                <TextField small label i18n="contacts" {...attachInput('email')} className="col-lg-50" />
            </div>
            <div className="row">
                <Checkbox small label i18n="contacts" {...attachInput('internal')} />
            </div>
        </Modal >
    );
}

export default ContactForm;