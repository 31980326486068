// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=scrollable-list] > .input-body {
  overflow: hidden;
  height: 100%;
}
.input[input-type=scrollable-list] > .input-body > .input-field {
  height: unset;
  overflow: hidden;
  height: 100%;
}
.input[input-type=scrollable-list] > .input-body > .input-field > .scrollable-list {
  overflow-y: auto;
  border: var(--theme-input-border) 1px solid;
  background-color: var(--theme-input-background);
  border-radius: 4px;
  height: 100%;
}
.input[input-type=scrollable-list] > .input-body > .input-field > .scrollable-list > * {
  padding: 4px 8px;
  cursor: pointer;
}
.input[input-type=scrollable-list] > .input-body > .input-field > .scrollable-list > *:hover {
  background-color: var(--theme-input-background-hover);
}
.input[input-type=scrollable-list] > .input-body > .input-field > .scrollable-list > *.selected {
  background-color: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ScrollableList/index.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,YAAA;AAAR;AAEQ;EACI,aAAA;EACA,gBAAA;EACA,YAAA;AAAZ;AAEY;EACI,gBAAA;EAEA,2CAAA;EACA,+CAAA;EACA,kBAAA;EACA,YAAA;AADhB;AAGgB;EACI,gBAAA;EACA,eAAA;AADpB;AAGoB;EACI,qDAAA;AADxB;AAIoB;EACI,4CAAA;AAFxB","sourcesContent":[".input[input-type=\"scrollable-list\"] {\n    & > .input-body {\n        overflow: hidden;\n        height: 100%;\n\n        & > .input-field {\n            height: unset;\n            overflow: hidden;\n            height: 100%;\n\n            & > .scrollable-list {\n                overflow-y: auto;\n\n                border: var(--theme-input-border) 1px solid;\n                background-color: var(--theme-input-background);\n                border-radius: 4px;\n                height: 100%;\n\n                & > * {\n                    padding: 4px 8px;\n                    cursor: pointer;\n\n                    &:hover {\n                        background-color: var(--theme-input-background-hover);\n                    }\n\n                    &.selected {\n                        background-color: var(--theme-color-success);\n                    }\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
