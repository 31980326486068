// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nothing {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-grey-medium);
  font-size: 13px;
  gap: 8px;
}
.nothing > svg {
  width: 72px;
  height: 72px;
  fill: var(--theme-color-grey-medium);
}
.nothing > span {
  margin-top: 12px;
}
.nothing a, .nothing .link {
  font-size: 16px;
}
.nothing.nothing-inline {
  flex-direction: row;
}
.nothing.nothing-inline > svg {
  width: 24px;
  height: 24px;
}
.nothing.nothing-inline > span {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Nothing/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,qCAAA;EACA,eAAA;EACA,QAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,oCAAA;AACR;AAEI;EACI,gBAAA;AAAR;AAGI;EAEI,eAAA;AAFR;AAKI;EACI,mBAAA;AAHR;AAKQ;EACI,WAAA;EACA,YAAA;AAHZ;AAMQ;EACI,aAAA;AAJZ","sourcesContent":[".nothing {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    color: var(--theme-color-grey-medium);\n    font-size: 13px;\n    gap: 8px;\n\n    & > svg {\n        width: 72px;\n        height: 72px;\n        fill: var(--theme-color-grey-medium);\n    }\n\n    & > span {\n        margin-top: 12px;\n    }\n\n    & a,\n    & .link {\n        font-size: 16px;\n    }\n\n    &.nothing-inline {\n        flex-direction: row;\n\n        & > svg {\n            width: 24px;\n            height: 24px;\n        }\n\n        & > span {\n            margin-top: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
