import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../form";
import { CloseIcon } from "../../icons";
import { addInArrayIfNotExists, toggleInArray } from "../../utils/objects";
import Search, { SearchResult, SearchResultItem } from "../Search";
import Tree from "../Tree";
import './index.scss';

interface DataFiltersProps<T> {
    icon?: ReactNode;
    label: string;
    value?: T[];
    onChange: (v: T[]) => void;
}

interface DataFilterWrapperProps<T> extends Omit<DataFiltersProps<T>, 'onChange'> {
    children: ReactNode;
}

export const DataFilterWrapper = <T,>({ icon, label, value, children }: DataFilterWrapperProps<T>) => (
    <div className="data-filter">
        <div className="data-filter-label">
            {!!icon && <div className="data-filter-label-icon">{icon}</div>}
            <span>{label}</span>
            {((Array.isArray(value) && !!value.length) || (!Array.isArray(value) && !!value)) && <div className="data-filter-label-pill">{Array.isArray(value) ? value.length : ''}</div>}
        </div>
        {children}
    </div>
);

interface DataFiltersProps<T> {
    icon?: ReactNode;
    label: string;
    value?: T[];
    onChange: (v: T[]) => void;
}

interface SearchFilterItemProps<T> {
    result: SearchResultItem<T>;
    onRadiusChange: (v: number) => void;
    onRemove: (r: SearchResultItem<T>) => void;
}

const SearchFilterItem = <T,>({ result, onRadiusChange, onRemove }: SearchFilterItemProps<T>) => {
    const { t } = useTranslation();
    return (
        <div className="search-filter-item">
            <div className="search-filter-item-label">
                <span>{result.label}</span>
                {!!result.description && <span>{result.description}</span>}
            </div>
            {result.radius !== undefined && (
                <TextField
                    inline
                    small
                    label={t('filters:radius')}
                    id="radius"
                    value={String(result.radius ?? 0)}
                    onChange={(v) => onRadiusChange(v ? Number(v) : 0)}
                />
            )}
            <CloseIcon onClick={() => onRemove(result)} />
        </div>
    );
}

export interface SearchFilterProps<T> extends DataFiltersProps<SearchResultItem<T>> {
    endpoint: string;
    withRadius?: boolean | ((e: SearchResult<T>) => boolean);
    withTags?: boolean | string;
    withMatch?: boolean;
    placeholder?: string;
    additionalFilters?: any;
}

export const SearchFilter = <T,>({
    icon,
    label,
    endpoint,
    withRadius,
    withTags,
    withMatch,
    placeholder,
    value,
    onChange,
    additionalFilters
}: SearchFilterProps<T>) => {
    const { t } = useTranslation();

    const handleRadiusChange = useCallback((key: string | number, radius: number) => {
        if (!value?.length) return;
        const i = value.findIndex(r => r.key === key);

        if (i === -1) return;

        const _value = [...value];
        _value[i].radius = radius;

        onChange(_value);
    }, [value, onChange]);

    const handleSearchResult = useCallback((r: SearchResult<T>) => {
        onChange(addInArrayIfNotExists(value, { ...r, radius: withRadius ? 0 : undefined }, (r1, r2) => r1.key === r2.key));
    }, [value, onChange]);

    const handleRemove = useCallback((r: SearchResult<T>) => {
        onChange(toggleInArray(value, r, (r1, r2) => r1.key === r2.key));
    }, [value, onChange]);

    const handleMatch = useCallback((keyword: string) => {
        onChange(addInArrayIfNotExists(value, { key: keyword, label: `${t('filters:matching')} '${keyword}'` }, (r1, r2) => r1.key === r2.key));
    }, [value, onChange]);

    return (
        <DataFilterWrapper icon={icon} label={label} value={value} >
            <div className="data-filter-input">
                <Search<T>
                    placeholder={placeholder}
                    endpoint={endpoint}
                    onClick={(e) => handleSearchResult({ ...e, entity: undefined })}
                    onMatchClick={withMatch ? handleMatch : undefined}
                    withTags={withTags}
                    additionalFilters={additionalFilters}
                />
            </div>
            <div className="data-filter-items search-filter-items">
                {!!value?.length && value.map(r => (
                    <SearchFilterItem
                        key={r.key}
                        result={r}
                        onRadiusChange={(radius) => handleRadiusChange(r.key, radius)}
                        onRemove={handleRemove}
                    />
                ))}
            </div>
        </DataFilterWrapper>
    )
}

export interface ButtonsFilterProps<T> extends DataFiltersProps<T> {
    buttons: { key: T, label: string, icon?: ReactNode }[];
}

export const ButtonsFilter = <T,>({
    icon,
    label,
    buttons,
    value,
    onChange
}: ButtonsFilterProps<T>) => (
    <DataFilterWrapper icon={icon} label={label} value={value} >
        <div className="data-filter-items buttons-filter-items">
            {buttons.map(button => (
                <div
                    key={String(button.key)}
                    className={value?.includes(button.key) ? 'active' : ''}
                    onClick={() => onChange(toggleInArray(value, button.key))}
                >
                    {!!button.icon && <div>{button.icon}</div>}
                    <span>{button.label}</span>
                </div>
            ))}
        </div>
    </DataFilterWrapper>
)

export interface TreeFilterProps extends DataFiltersProps<string | number> {
    endpoint: string;
    withRoot?: boolean;
}

export const TreeFilter = ({
    icon,
    label,
    withRoot,
    value,
    endpoint,
    onChange
}: TreeFilterProps) => {

    const handleChange = useCallback((keys: (string | number)[]) => {
        onChange(keys)
    }, [onChange]);

    return (
        <DataFilterWrapper icon={icon} label={label} value={value} >
            <Tree allowParentSelect withRoot={withRoot} endpoint={endpoint} onSelect={handleChange} selected={value} />
        </DataFilterWrapper>
    );
}
