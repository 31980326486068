import { LngLatBounds } from 'mapbox-gl';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Marker, Popup } from "react-map-gl";
import { useSearchParams } from "react-router-dom";
import useSuperCluster from "use-supercluster";
import CompanyCard from '../../components/CompanyCard';
import CompanySmallList from '../../components/CompanySmallList';
import { Company } from '../../models/company';
import { useMapContext } from "../../sg-react/context";
import { MapFilters } from "../../sg-react/context/MapContext";
import { Map } from '../../sg-react/data';
import { MapRefProps } from "../../sg-react/data/Map";
import { useRequest } from '../../sg-react/hooks';
import { BulletIcon, FactoryIcon, FiltersHorizontalIcon, WindTurbine, WindTurbines } from '../../sg-react/icons';
import { Button } from '../../sg-react/ui';
import MainMapFilters from "./MainMapFilters";
import MainMapResults from './MainMapResults';
import './index.scss';
import { Dependency } from '../../models/dependency';

const clusterOptions = {
    radius: 40,
    maxZoom: 15,
    map: (props: any) => ({
        companies: [props.company]
    }),
    reduce: (acc: any, props: any) => {
        acc.companies = [...acc.companies, ...props.companies];
        return acc;
    }
};

const MainMap = () => {
    const { t } = useTranslation();
    const { filters } = useMapContext();
    const request = useRequest();
    const [isFilterPanelVisible, setFilterPanelVisible] = useState<boolean>(false);
    const [isResultsPanelVisible, setResultsPanelVisible] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [popup, setPopup] = useState<{ lng: number, lat: number, companies?: Company[] } | null>(null);
    const [bounds, setBounds] = useState<LngLatBounds | null>(null);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [searchParams] = useSearchParams();
    const mapRef = useRef<MapRefProps>(null);

    const { clusters } = useSuperCluster({
        points: companies.map(company => ({
            type: "Feature",
            properties: { cluster: false, point_count: 1, company },
            geometry: { "type": "Point", "coordinates": [company.lng, company.lat] }
        })),
        bounds: bounds ? [
            bounds.getSouthWest().lng,
            bounds.getSouthWest().lat,
            bounds.getNorthEast().lng,
            bounds.getNorthEast().lat
        ] : undefined,
        zoom: mapRef.current?.getZoom() ?? 12,
        options: clusterOptions,
    });

    const get = useCallback((filters: MapFilters) => {
        if (!filters.fetch) return;

        setPopup(null);
        setSelectedCompany(null);
        request.get<Company[]>(filters.mode === 'internal' ? '/directory/view' : '/directory/view', { params: filters, loader: true })
            .then((data) => {
                setCompanies(data);
                if (data.length === 1) {
                    setSelectedCompany(data[0]);
                }
                mapRef.current?.fitToEntities(data);
            })
            .catch(() => null);
    }, []);

    const markers: ReactElement[] = useMemo<ReactElement[]>(() =>
        clusters.map((c => {
            if (c.properties.cluster) {
                return (<Marker
                    key={`cluster-${c.id}`}
                    longitude={c.geometry.coordinates[0]}
                    latitude={c.geometry.coordinates[1]}
                    anchor="center"
                    onClick={() => { mapRef.current?.fitToEntities((c.properties as any).companies as Company[], { animate: true }); }}
                >
                    <div
                        className={`marker-cluster marker-cluster-${filters.mode}`}
                        style={!!companies?.length ? { width: `${30 + (c.properties.point_count / companies?.length) * 50}px`, height: `${30 + (c.properties.point_count / companies?.length) * 50}px` } : undefined}
                        onMouseEnter={() => setPopup({
                            lng: c.geometry.coordinates[0],
                            lat: c.geometry.coordinates[1],
                            companies: (c.properties as any).companies as Company[]
                        })}
                    >
                        {c.properties.point_count}
                    </div>
                </Marker >
                );
            }

            const directory = (c.properties as any).company as Company;
            const isWindTurbines = directory.qualifiers?.some(q => q.id === 1807);

            const markers = [
                <Marker
                    key={`marker-${directory.id}`}
                    longitude={c.geometry.coordinates[0]}
                    latitude={c.geometry.coordinates[1]}
                    onClick={() => setSelectedCompany(directory)}
                    anchor="center"
                >
                    <div className={`marker-company ${isWindTurbines ? 'marker-company-wind-turbines' : ''}`}
                        onMouseEnter={() => setPopup({
                            lng: c.geometry.coordinates[0],
                            lat: c.geometry.coordinates[1],
                            companies: [directory]
                        })}
                    >
                        {isWindTurbines
                            ? <WindTurbines />
                            : <FactoryIcon />
                        }
                    </div>
                </Marker>
            ];

            if (directory.dependencies?.length) {
                const dependencies: Dependency[] = directory?.dependencies;

                markers.push(...dependencies.map(d => (
                    <Marker
                        key={`marker-dependency-${d.id}`}
                        longitude={d.lng}
                        latitude={d.lat}
                        anchor="center"
                    >
                        <div className="marker-dependency">
                            <WindTurbine />
                        </div>
                    </Marker>
                )));
            }

            return markers;
        })).flat(),
        [clusters]);

    useEffect(() => {
        get(filters);
    }, [filters]);

    return (
        <div id="main-map">
            <div id="main-map-filters" className={isFilterPanelVisible ? 'visible' : ''}>
                <MainMapFilters />
            </div>
            <div id="main-map-results" className={isResultsPanelVisible ? 'visible' : ''}>
                <MainMapResults companies={companies} onClick={setSelectedCompany} />
            </div>
            <div id="main-map-container">
                <div id="main-map-float">
                    {selectedCompany && <CompanyCard company={selectedCompany} onClose={() => setSelectedCompany(null)} />}
                </div>
                <div id="main-map-filters-buttons">
                    <Button
                        color={isFilterPanelVisible ? 'accent' : 'secondary'}
                        label={t('filters:filters')}
                        onClick={() => setFilterPanelVisible(!isFilterPanelVisible)}
                        icon={<FiltersHorizontalIcon />}
                        iconPosition="left"
                    />
                    <Button
                        color={isResultsPanelVisible ? 'accent' : 'secondary'}
                        label={t('filters:results')}
                        onClick={() => setResultsPanelVisible(!isResultsPanelVisible)}
                        icon={<BulletIcon />}
                        iconPosition="left"
                    />
                </div>
                <Map
                    markers={markers}
                    onMoveEnd={(e) => setBounds(e.target.getBounds())}
                    ref={mapRef}
                >
                    {!!selectedCompany && (
                        <Marker
                            longitude={selectedCompany.lng}
                            latitude={selectedCompany.lat}
                            anchor="center"
                            style={{ zIndex: 30 }}
                        />
                    )}
                    {!!popup && !!popup.companies?.length && (
                        <Popup
                            longitude={popup.lng}
                            latitude={popup.lat}
                            anchor="left"
                            offset={10}
                            className="main-map-popup"
                            onClose={() => setPopup(null)}
                        >
                            <CompanySmallList companies={popup.companies} onClick={setSelectedCompany} />
                        </Popup>
                    )}
                </Map>
            </div>
        </div>
    )
}

export default MainMap;