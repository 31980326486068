// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-panel {
  display: flex;
  flex-direction: column;
  border-right: var(--theme-container-border) 1px solid;
  padding: 12px;
}
.filters-panel .filters-panel-header {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 8px;
  font-size: 18px;
  flex-shrink: 0;
  padding: 12px;
  cursor: pointer;
}
.filters-panel .filters-panel-header > svg {
  width: 20px;
  height: 20px;
  fill: var(--theme-color-grey-medium);
}
.filters-panel .filters-panel-header > span {
  flex-grow: 1;
}
.filters-panel > .filters-panel-item {
  display: flex;
  flex-direction: column;
  border-left: transparent 4px solid;
  padding: 0 6px;
}
.filters-panel > .filters-panel-item.active {
  border-left: var(--theme-color-accent) 4px solid;
}
.filters-panel > .filters-panel-item > h4 {
  margin-top: 16px;
  margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/FiltersPanel/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,qDAAA;EACA,aAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,QAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,oCAAA;AACZ;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,aAAA;EACA,sBAAA;EACA,kCAAA;EACA,cAAA;AAFR;AAIQ;EACI,gDAAA;AAFZ;AAKQ;EACI,gBAAA;EACA,kBAAA;AAHZ","sourcesContent":[".filters-panel {\n    display: flex;\n    flex-direction: column;\n    border-right: var(--theme-container-border) 1px solid;\n    padding: 12px;\n\n    & .filters-panel-header {\n        display: flex;\n        align-items: center;\n        height: 36px;\n        gap: 8px;\n        font-size: 18px;\n        flex-shrink: 0;\n        padding: 12px;\n        cursor: pointer;\n\n        & > svg {\n            width: 20px;\n            height: 20px;\n            fill: var(--theme-color-grey-medium);\n        }\n\n        & > span {\n            flex-grow: 1;\n        }\n    }\n\n    & > .filters-panel-item {\n        display: flex;\n        flex-direction: column;\n        border-left: transparent 4px solid;\n        padding: 0 6px;\n\n        &.active {\n            border-left: var(--theme-color-accent) 4px solid;\n        }\n\n        & > h4 {\n            margin-top: 16px;\n            margin-bottom: 4px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
