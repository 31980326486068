// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-sub-nav {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.layout-sub-nav > .layout-sub-nav-content {
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/LayoutSubNav/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,sBAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACR","sourcesContent":[".layout-sub-nav {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n\n    & > .layout-sub-nav-content {\n        flex-grow: 1;\n        display: flex;\n        overflow-y: auto;\n        height: 100%;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
