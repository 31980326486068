import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapFilters, useMapContext } from "../../sg-react/context/MapContext";
import { SearchFilter } from "../../sg-react/data";
import { DataFilterWrapper, TreeFilter } from "../../sg-react/data/DataFilters";
import { Switch, TextField } from "../../sg-react/form";
import { FiltersHorizontalIcon, GlobeIcon, LocationIcon, TreeIcon, TypingIcon } from "../../sg-react/icons";
import { Geoname } from "../../sg-react/models/geo";
import { Button } from "../../sg-react/ui";

const MainMapFilters = () => {
    const { t, i18n } = useTranslation();
    const { filters, setFilters, withSourcing } = useMapContext();
    const [tempFilters, setTempFilters] = useState<MapFilters>({ ...filters });

    const handleSubmit = useCallback((f: MapFilters) => {
        setFilters({ ...f, fetch: true, companies: undefined });
    }, [setFilters]);

    useEffect(() => {
        setTempFilters(filters);
    }, [filters]);

    return (
        <Fragment>
            <div id="main-map-filters-content">
                <div className="main-map-panel-header">
                    <FiltersHorizontalIcon />
                    <span>{t('filters:filters')}</span>
                </div>
                {withSourcing && (
                    <DataFilterWrapper icon={<GlobeIcon />} label="Mode">
                        <Switch<'internal' | 'sourcing'>
                            small
                            id="data-filters-switch"
                            items={[{ key: 'internal', label: 'Interne' }, { key: 'sourcing', label: 'Sourcing' }]}
                            value={tempFilters.mode}
                            onChange={(mode) => mode ? setTempFilters({ ...tempFilters, mode, qualifiers: undefined }) : null}
                        />
                    </DataFilterWrapper>
                )}
                <DataFilterWrapper icon={<TypingIcon />} label={t('search:keyword')}>
                    <TextField
                        id="map-keyword"
                        small
                        value={tempFilters.keyword}
                        onChange={(keyword) => setTempFilters({ ...tempFilters, keyword: keyword ? keyword : undefined })}
                    />
                </DataFilterWrapper>
                <SearchFilter<Geoname>
                    icon={<LocationIcon />}
                    label={t('geo:label')}
                    placeholder={t('geo:search')}
                    endpoint={`/geo/search/${i18n.language}`}
                    withRadius
                    withTags="geo"
                    value={tempFilters.geonames}
                    onChange={geonames => setTempFilters({ ...tempFilters, geonames })}
                />
                <TreeFilter
                    icon={<TreeIcon />}
                    label={t('edf:sectors')}
                    endpoint={tempFilters.mode === 'internal' ? '/qualifiers/tree/1800' : '/qualifiers/tree'}
                    withRoot={tempFilters.mode === 'sourcing'}
                    value={tempFilters.qualifiers}
                    onChange={qualifiers => setTempFilters({ ...tempFilters, qualifiers })}
                />
            </div>
            <div id="main-map-filters-footer">
                <Button label={t('filters:clear')} onClick={() => setFilters({ mode: tempFilters.mode })} color="secondary" />
                <Button label={t('filters:search')} onClick={() => handleSubmit(tempFilters)} color="primary" />
            </div>
        </Fragment>
    )
}

export default MainMapFilters;