import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "..";
import { SimpleList } from "../../data";
import { TextField } from "../../form";
import { useRequest } from "../../hooks";
import { FloppyIcon, FolderIcon } from "../../icons";
import './index.scss';
import { SimpleListSchemaColumn } from "../../data/SimpleList";

interface PresetsProps<T> {
    getEndpoint: string;
    crudEndpoint: string;
    schema: SimpleListSchemaColumn<T>[];
    initial?: Partial<T>;
    primaryKey: keyof T;
    value?: any;
    onLoad: (e: T) => void;

}

const Presets = <T extends { name: string }>({
    getEndpoint,
    crudEndpoint,
    schema,
    value,
    initial,
    onLoad,
    primaryKey
}: PresetsProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [modalMode, setModalMode] = useState<'save' | 'open' | null>(null);
    const [modalSelectedPreference, setModalSelectedPreference] = useState<T | null>(null);
    const [modalPreferenceName, setModalPreferenceName] = useState<string>('');

    const handleSubmitPreference = useCallback(async (data?: any) => {
        if (modalMode === 'open') {
            if (!modalSelectedPreference) return;

            onLoad(modalSelectedPreference);
            setModalMode(null);
            setModalPreferenceName('');
            setModalSelectedPreference(null);

        } else {
            if (!modalSelectedPreference && !modalPreferenceName) return;

            const dto = {
                ...initial,
                ...modalSelectedPreference,
                name: !!modalPreferenceName ? modalPreferenceName : modalSelectedPreference?.name,
                data: data ?? {}
            };

            (modalSelectedPreference ? request.put : request.post)(crudEndpoint, dto, { i18n: true, successMessage: 'success.' + (modalSelectedPreference ? 'update' : 'create'), errorMessage: 'error.server_error' })
                .then(() => {
                    setModalMode(null);
                    setModalPreferenceName('');
                    setModalSelectedPreference(null);
                })
                .catch(() => null);
        }
    }, [modalMode, modalSelectedPreference, modalPreferenceName, crudEndpoint, onLoad, initial, request]);

    const handleDelete = useCallback(async (p: T): Promise<boolean> => {
        return request
            .delete(`${crudEndpoint}/${p[primaryKey]}`, { i18n: true, errorMessage: 'error.server_error' })
            .then(() => true)
            .catch(() => false);
    }, [crudEndpoint]);

    return (
        <div className="presets">
            <Button color="menu" label={t('actions:open')} icon={<FolderIcon />} onClick={() => setModalMode('open')} />
            <Button color="menu" label={t('actions:save')} icon={<FloppyIcon />} onClick={() => setModalMode('save')} />
            {!!modalMode && (
                <Modal
                    header={t('actions:' + modalMode)}
                    onClose={() => setModalMode(null)}
                    onSubmit={() => handleSubmitPreference(value)}
                    className="filters-modal"
                >
                    <SimpleList<T>
                        endpoint={getEndpoint}
                        primaryKey={primaryKey}
                        schema={schema}
                        actions={{
                            select: setModalSelectedPreference,
                            delete: handleDelete
                        }}
                    />
                    {modalMode === 'save' && (
                        <TextField
                            label={modalSelectedPreference ? `${t('actions:rename')} ${modalSelectedPreference.name}` : t('actions:save_as')}
                            id="preferenceName"
                            onChange={(v) => setModalPreferenceName(v as string)}
                            value={modalPreferenceName}
                            small
                        />
                    )}
                </Modal>
            )}
        </div>
    )
}

export default Presets;