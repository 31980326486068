// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  text-align: center;
  padding: 8px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.pagination > * {
  margin: 0 8px;
  font-size: 14px;
}
.pagination > *.arrows {
  cursor: pointer;
}
.pagination > *.page {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination > *.page.active {
  color: var(--theme-color-accent);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/Pagination/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,eAAA;AACR;AACQ;EACI,eAAA;AACZ;AAEQ;EACI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAZ;AAEY;EACI,gCAAA;EACA,iBAAA;AAAhB","sourcesContent":[".pagination {\n    width: 100%;\n    text-align: center;\n    padding: 8px;\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    justify-content: center;\n\n    & > * {\n        margin: 0 8px;\n        font-size: 14px;\n\n        &.arrows {\n            cursor: pointer;\n        }\n\n        &.page {\n            cursor: pointer;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n\n            &.active {\n                color: var(--theme-color-accent);\n                font-weight: bold;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
