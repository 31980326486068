import { Fragment, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DataListColumnFilters, DataListColumnWithWidth } from ".";
import { DownIcon, HideIcon, ShowIcon, SortDownIcon, SortUpIcon, UpIcon } from "../../icons";
import { Dropdown, Menu, MenuDivider, MenuItem } from "../../ui";
import { MenuList, MenuTextField } from "../../ui/Menu";

interface ColumnHeaderProps<T> {
    column: DataListColumnWithWidth<T>;
    filters?: DataListColumnFilters;
    order?: 1 | -1;
    onResize: (s: number) => void;
    onFilterChange: (filters: DataListColumnFilters) => void;
    onOrder: (direction: 1 | -1) => void;
}

const ColumnHeader = <T,>({ column, order, filters, onResize, onFilterChange, onOrder }: ColumnHeaderProps<T>) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const resizingRef = useRef<boolean>(false);

    const hasFilter = useMemo(() => filters && Object.keys(filters).some(k => !!filters[k as keyof DataListColumnFilters]), [filters]);

    useEffect(() => {
        const handleWindowMouseMove = (event: MouseEvent) => {
            if (containerRef.current && resizingRef.current) {
                const bbox = containerRef.current.getBoundingClientRect();
                const width = event.clientX - bbox.left;
                if (width > 50) {
                    onResize(width);
                }
            }
        };
        const handleStopResizing = () => {
            if (resizingRef.current) {
                resizingRef.current = false;
            }
        };
        window.addEventListener('mousemove', handleWindowMouseMove);
        window.addEventListener('mouseup', handleStopResizing);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
            window.removeEventListener(
                'mouseup',
                handleStopResizing,
            );
        };
    }, []);

    return (
        <div
            ref={containerRef}
            className={`data-list-column-header ${hasFilter ? 'filtered' : ''}`}
            style={{ width: column.widthPx + 'px', minWidth: column.widthPx + 'px' }}
        >
            <Dropdown
                dropdown={
                    <Menu>
                        {!!column.options?.startsWith && (
                            <Fragment>
                                <MenuTextField placeholder={t('filters:filter') + '...'} value={filters?.startsWith} onChange={(v) => onFilterChange({ ...filters, startsWith: !!v ? v : undefined })} />
                                <MenuDivider />
                            </Fragment>
                        )}
                        {!!column.options?.ilike && (
                            <Fragment>
                                <MenuTextField placeholder={t('filters:filter') + '...'} value={filters?.ilike} onChange={(v) => onFilterChange({ ...filters, ilike: !!v ? v : undefined })} />
                                <MenuDivider />
                            </Fragment>
                        )}
                        {!!column.options?.search && (
                            <Fragment>
                                <MenuTextField placeholder={t('filters:search') + '...'} value={filters?.search} onChange={(v) => onFilterChange({ ...filters, search: !!v ? v : undefined })} />
                                <MenuDivider />
                            </Fragment>
                        )}
                        {!!column.options?.in && !!column.values && (
                            <Fragment>
                                <MenuList
                                    value={filters?.in}
                                    onChange={(v) => onFilterChange({ ...filters, in: v?.length ? v as any : undefined })}
                                    items={column.values}
                                />
                                <MenuDivider />
                            </Fragment>
                        )}
                        {!!column.options?.null && (
                            <Fragment>
                                {!filters?.null && <MenuItem icon={<ShowIcon />} label={t('filters:show_empty')} onClick={() => onFilterChange({ ...filters, null: true })} />}
                                {!!filters?.null && <MenuItem icon={<HideIcon />} label={t('filters:show_all')} onClick={() => onFilterChange({ ...filters, null: false })} />}
                                <MenuDivider />
                            </Fragment>
                        )}
                        {!!column.options?.order && (
                            <Fragment>
                                <MenuItem icon={<SortUpIcon />} label={`${t('filters:sort')} ${t('filters:ascending')}`} onClick={() => onOrder(1)} />
                                <MenuItem icon={<SortDownIcon />} label={`${t('filters:sort')} ${t('filters:descending')}`} onClick={() => onOrder(-1)} />
                            </Fragment>
                        )}
                    </Menu>
                }
                closeOnClick
            >
                <div className="column-header-label">{column.i18n ? t(column.i18n, { defaultValue: column.label }) : column.label}</div>
                {!!column.options?.order && (
                    <div className="column-header-order">
                        <UpIcon className={order === 1 ? 'active' : ''} />
                        <DownIcon className={order === -1 ? 'active' : ''} />
                    </div>
                )}
            </Dropdown>
            <div className="data-list-column-header-border" onMouseDown={() => resizingRef.current = true} />
        </div>
    )
}

export default ColumnHeader;