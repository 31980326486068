import { useTranslation } from "react-i18next";
import CompanySmallList from "../../components/CompanySmallList";
import { Company } from "../../models/company";
import { BulletIcon } from "../../sg-react/icons";

interface MainMapResultsProps {
    companies: Company[];
    onClick: (c: Company) => void;
}

const MainMapResults = ({ companies, onClick }: MainMapResultsProps) => {
    const { t } = useTranslation();

    return (
        <div id="main-map-results-panel">
            <div className="main-map-panel-header">
                <BulletIcon />
                <span>{t('filters:results')}</span>
            </div>
            <div id="main-map-results-panel-content">
                <CompanySmallList companies={companies} onClick={onClick} />
            </div>
        </div>
    )
}

export default MainMapResults;