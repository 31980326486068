import { ResponseType } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUiContext } from "../context";
import AxiosInstance from "../services/Axios";

interface RequestOptions {
    authorization: boolean;
    params: any;
    i18n: boolean | string;
    loader: boolean;
    successMessage: string;
    errorMessage: string;
    responseType: ResponseType;
    timeout: number;
    headers: Record<string, string>;
}

interface UseRequestReturn {
    get: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
    post: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    put: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    patch: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    delete: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
}

const useRequest = (): UseRequestReturn => {
    const { setToastError, setToastSuccess, setSpinnerVisible } =
        useUiContext();
    const { t } = useTranslation();

    const request = useCallback(
        async <T>(
            method: "get" | "post" | "put" | "patch" | "delete",
            url: string,
            body: any,
            options?: Partial<RequestOptions>
        ): Promise<T> => {
            if (options?.loader) {
                setSpinnerVisible(true);
            }

            try {
                let data: T;
                const _options = {
                    params: options?.params,
                    responseType: options?.responseType,
                    timeout: options?.timeout,
                    headers: {
                        ...options?.headers,
                        Authorization: `Bearer ${sessionStorage.getItem(
                            "sg-token"
                        )}`,
                    },
                };

                switch (method) {
                    case "get":
                        const getResponse = await AxiosInstance.get<T>(
                            url,
                            _options
                        );
                        data = getResponse.data;
                        break;
                    case "post":
                        const postResponse = await AxiosInstance.post<T>(
                            url,
                            body,
                            _options
                        );
                        data = postResponse.data;
                        break;
                    case "put":
                        const putResponse = await AxiosInstance.put<T>(
                            url,
                            body,
                            _options
                        );
                        data = putResponse.data;
                        break;
                    case "patch":
                        const patchResponse = await AxiosInstance.patch<T>(
                            url,
                            body,
                            _options
                        );
                        data = patchResponse.data;
                        break;
                    default:
                        const deleteResponse = await AxiosInstance.delete<T>(
                            url,
                            _options
                        );
                        data = deleteResponse.data;
                        break;
                }

                if (options?.successMessage) {
                    setToastSuccess(
                        options.i18n
                            ? t(
                                  `${
                                      options.i18n === true
                                          ? "message"
                                          : options.i18n
                                  }:${options.successMessage}`
                              )
                            : options.successMessage
                    );
                }

                return data;
            } catch (e) {
                if (options?.errorMessage) {
                    setToastError(
                        options.i18n
                            ? t(
                                  `${
                                      options.i18n === true
                                          ? "message"
                                          : options.i18n
                                  }:${options.errorMessage}`
                              )
                            : options.errorMessage
                    );
                }

                throw e;
            } finally {
                if (options?.loader) {
                    setSpinnerVisible(false);
                }
            }
        },
        []
    );

    return {
        get: <T>(url: string, options?: Partial<RequestOptions>) =>
            request<T>("get", url, {}, options),
        post: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("post", url, body, options),
        put: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("put", url, body, options),
        patch: <T>(url: string, body: any, options?: Partial<RequestOptions>) =>
            request<T>("patch", url, body, options),
        delete: <T>(url: string, options?: Partial<RequestOptions>) =>
            request<T>("delete", url, {}, options),
    };
};

export default useRequest;
