import { getStringValue } from "./format";

// Object
const primaryKeysArray = <T>(primaryKey: keyof T | (keyof T)[]) =>
    Array.isArray(primaryKey) ? primaryKey : [primaryKey];

export const primaryKeysEquals = <T>(
    obj1: Partial<T>,
    obj2: Partial<T>,
    primaryKey: keyof T | (keyof T)[]
): boolean => primaryKeysArray(primaryKey).every((k) => obj1[k] === obj2[k]);

export const hasPrimaryKeys = <T>(
    obj: Partial<T>,
    primaryKey: keyof T | (keyof T)[]
): boolean => !primaryKeysArray(primaryKey).some((k) => !obj[k]);

export const getPrimaryKeysAsString = <T>(
    obj: Partial<T>,
    primaryKey: keyof T | (keyof T)[]
): string => getStringValue(primaryKeysArray(primaryKey).map((k) => obj[k]));

// Object fields
export const getNestedField = (entity: any, field: string): any => {
    if (!entity) return undefined;

    const fieldSplit = field.split(".");
    if (fieldSplit.length === 1) {
        return entity[field];
    } else {
        const firstItem = fieldSplit.shift();
        return getNestedField(entity[firstItem!], fieldSplit.join("."));
    }
};

export const changeFieldValue = (entity: any, field: string, value: any) => {
    const _entity = { ...entity };

    const fieldSplit = field.split(".");

    if (fieldSplit.length === 1) {
        _entity[field] = value;
    } else {
        const firstItem = fieldSplit.shift();
        _entity[firstItem!] = changeFieldValue(
            entity[firstItem!] ?? {},
            fieldSplit.join("."),
            value
        );
    }

    return _entity;
};

export const removeFieldValue = (entity: any, field: string) => {
    if (!entity) return;
    const _entity = { ...entity };

    const fieldSplit = field.split(".");
    if (fieldSplit.length === 1) {
        delete _entity[field];
    } else {
        const firstItem = fieldSplit.shift();
        _entity[field] = removeFieldValue(
            entity[firstItem!],
            fieldSplit.join(".")
        );
    }

    return _entity;
};

// Array
export const toggleInArray = <T>(
    array: T[] | undefined,
    item: T,
    callback?: (obj1: T, obj2: T) => boolean
): T[] => {
    const _array = [...(array ?? [])];
    const index = _array.findIndex((v) =>
        callback ? callback(v, item) : v === item
    );

    if (index === -1) {
        _array.push(item);
    } else {
        _array.splice(index, 1);
    }

    return _array;
};

export const addInArrayIfNotExists = <T>(
    array: T[] | undefined,
    item: T,
    callback?: (obj1: T, obj2: T) => boolean
): T[] => {
    const _array = [...(array ?? [])];
    const index = _array.findIndex((v) =>
        callback ? callback(v, item) : v === item
    );

    if (index === -1) {
        _array.push(item);
    }

    return _array;
};

export const addOrReplaceInArray = <T>(
    array: T[] | undefined,
    item: T,
    callback?: (obj1: T, obj2: T) => boolean
): T[] => {
    const _array = [...(array ?? [])];
    const index = _array.findIndex((v) =>
        callback ? callback(v, item) : v === item
    );

    if (index === -1) {
        _array.push(item);
    } else {
        _array[index] = item;
    }

    return _array;
};

export const moveInArray = <T>(
    array: T[],
    source: number,
    destination: number
): T[] => {
    if (
        source < 0 ||
        source >= array.length ||
        destination < 0 ||
        destination >= array.length ||
        source === destination
    ) {
        return array;
    }

    const _array = [...array];
    _array.splice(destination, 0, _array.splice(source, 1)[0]);

    return _array;
};

// Types
export const isType = <T>(
    obj: any,
    callback: (obj: any) => boolean
): obj is T => callback(obj);
export type DiffStatus = "deleted" | "added" | "modified" | "equals";

export type DiffArrayItem = {
    item: any;
    status: "added" | "deleted" | "equals";
};
export type DiffField<U> = {
    current?: U;
    updated?: U;
    status: DiffStatus;
    items?: DiffArrayItem[];
};

export type Diff<T> = {
    [key in keyof T]?: DiffField<T[key]>;
};

export const objectEquals = <U>(e1: U, e2: U): boolean => {
    if (e1 === e2) return true;
    if (!e1 || !e2) return false;
    if (Object(e1) !== e1) return e1 === e2;
    if (e1.hasOwnProperty("equals")) return (e1 as any).equals(e2);

    for (const key in e1) {
        if (e1[key] !== e2[key] && JSON.stringify(e1) !== JSON.stringify(e2))
            return false;
    }
    return true;
};

export const arrayDiff = <U>(e1: U, e2: U): DiffField<U> => {
    const items: DiffArrayItem[] = [];
    if (!e1 && e2) {
        items.push(
            ...((e2 as any[]).map((item) => ({
                item,
                status: "added",
            })) as DiffArrayItem[])
        );
    } else if (e1 && !e2) {
        items.push(
            ...((e1 as any[]).map((item) => ({
                item,
                status: "deleted",
            })) as DiffArrayItem[])
        );
    } else {
        items.push(
            ...((e1 as any[]).map((e1Item) => ({
                item: e1Item,
                status: (e2 as any[]).some((e2Item) =>
                    objectEquals(e1Item, e2Item)
                )
                    ? "equals"
                    : "deleted",
            })) as DiffArrayItem[])
        );

        items.push(
            ...((e2 as any[])
                .filter(
                    (e2Item) => !items.some((i) => objectEquals(i.item, e2Item))
                )
                .map((e2Item) => ({
                    item: e2Item,
                    status: "added",
                })) as DiffArrayItem[])
        );
    }

    return {
        current: e1,
        updated: e2,
        status: items.some((i) => i.status !== "equals")
            ? "modified"
            : "equals",
        items,
    };
};

export const fieldDiff = <U>(e1: U, e2: U): DiffStatus => {
    if (e1 === e2) return "equals";
    if (!e1 || !e2) return e1 ? "deleted" : "added";

    return objectEquals(e1, e2) ? "equals" : "modified";
};

export const objectDiff = <T>(e1: T, e2: T): Diff<T> => {
    const rest = { ...e2 };
    const diff: Diff<T> = {};

    let key: keyof T;
    for (key in e1) {
        if (!e1?.[key] && !e2?.[key]) continue;

        diff[key] = Array.isArray(e1[key])
            ? arrayDiff(e1[key], e2[key])
            : {
                  current: e1[key],
                  updated: e2[key],
                  status: fieldDiff(e1[key], e2[key]),
              };
        delete rest[key];
    }

    for (const key in rest) {
        if (!e2?.[key as keyof T]) continue;
        diff[key] = Array.isArray(e2[key])
            ? arrayDiff(e1[key], e2[key])
            : {
                  current: e1?.[key],
                  updated: e2[key],
                  status: fieldDiff(e1?.[key], e2[key]),
              };
    }

    return diff;
};
