import { Link, useNavigate } from 'react-router-dom';
import './index.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '../../icons';

export type Breadcrumb = { label: string, i18n?: boolean, href?: string };

export interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

const Breadcrumbs = ({
    breadcrumbs
}: BreadcrumbsProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div id="breadcrumbs" className="sm-hide">
            <HomeIcon id="breadcrumb-home-icon" onClick={() => navigate('/')} />
            <div className="breadcrumb-delimiter" />
            {breadcrumbs.map((breadcrumb, i) => i === breadcrumbs.length - 1
                ? <span key={i}>{breadcrumb.i18n ? t(breadcrumb.label) : breadcrumb.label}</span>
                : (
                    <Fragment key={i}>
                        <Link to={breadcrumb.href ?? '.'} key={i}>{breadcrumb.i18n ? t(breadcrumb.label) : breadcrumb.label}</Link>
                        <div className="breadcrumb-delimiter" />
                    </Fragment>
                )
            )}
        </div>
    );
}

export default Breadcrumbs;