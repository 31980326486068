import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { Role } from '../../utils/enums';
import './index.scss';

export interface HorizontalNavigationItem {
    key: string;
    path: string;
    label?: string;
    icon?: ReactNode;
    roles?: Role[];
}

interface HorizontalNavigationProps {
    items: HorizontalNavigationItem[];
    i18n?: boolean;
}

const HorizontalNavigation = ({ items, i18n }: HorizontalNavigationProps) => {
    const { currentUser, currentRole } = useAuthContext();
    const { t } = useTranslation();
    const location = useLocation();

    const navigationItems = useMemo(() => items
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => (
            <Link
                key={item.key}
                to={'/' + item.path}
                className={`horizontal-navigation-item ${(!item.path && location.pathname === '/') || ('/' + item.path === location.pathname || location.pathname.startsWith('/' + item.path + '/')) ? 'active' : ''}`}
            >
                {item.icon}
                <span>{i18n ? t('path:' + item.key) : item.label ?? item.key}</span>
            </Link>
        )), [items, currentRole, currentUser, location.pathname, i18n])


    return (
        <div className="horizontal-navigation">
            {navigationItems}
        </div>
    )
}

export default HorizontalNavigation;