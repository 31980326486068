import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkIcon } from '../../sg-react/icons';
import './index.scss';
import { Company } from '../../models/company';

interface CompanySmallListProps {
    companies: Company[];
    onClick?: (c: Company) => void;
}

const CompanySmallList = ({ companies, onClick }: CompanySmallListProps) => {

    return (
        <div className="company-small-list">
            {companies.map(company => (
                <div
                    key={company.id}
                    style={onClick ? { cursor: 'pointer' } : {}}
                    onClick={onClick ? (e) => { e.stopPropagation(); onClick(company); } : undefined}
                >
                    <span>{company.name}</span>
                    <span>{company.address}</span>
                </div>
            ))}
        </div>
    )
}

export default CompanySmallList;