import { ReactNode } from 'react';
import { HourglassIcon } from '../../icons';
import './index.scss';
import { useTranslation } from 'react-i18next';

export type ButtonColor = 'primary' | 'secondary' | 'accent' | 'success' | 'warning' | 'error' | 'menu' | 'disabled';

export interface ButtonProps {
    label?: string;
    icon?: ReactNode;
    className?: string;
    i18n?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    type?: 'button' | 'submit';
    color?: ButtonColor;
    iconPosition?: 'left' | 'right';
}

const Button = ({
    label,
    icon,
    className,
    i18n,
    onClick,
    disabled,
    loading,
    type,
    color,
    iconPosition
}: ButtonProps) => {
    const { t } = useTranslation();
    return (
        <button
            type={type ?? 'button'}
            className={`button button-${color} ${loading ? 'button-loading' : ''} ${className ?? ''}`}
            onClick={!disabled && !loading && type !== 'submit' ? onClick : undefined}
        >
            {!!icon && iconPosition === 'left' && icon}
            {!!label && <span>{i18n && label ? t(label) : label ?? ''}</span>}
            {!!icon && iconPosition !== 'left' && icon}
            <HourglassIcon className="button-loading-icon" />
        </button >
    );
}

export default Button;