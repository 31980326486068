import { useTranslation } from 'react-i18next';
import { FormPropsInterface } from '../InputWrapper';
import './index.scss';

const Checkbox = ({
    id,
    value,
    i18n,
    label,
    className,
    disabled,
    onChange,
}: FormPropsInterface<boolean>) => {
    const { t } = useTranslation();
    return (
        <div
            className={`checkbox ${value ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${className ?? ''}`}
            onClick={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
        >
            <input
                type="checkbox"
                checked={!!value}
                onChange={typeof onChange === 'function' && !disabled ? (e) => { e.stopPropagation(); onChange(!value); } : undefined}
                id={id}
                name={id}
            />
            <div className="mark" />
            {!!label && <label className={disabled ? 'disabled' : ''}>{i18n ? t(`${i18n}:${label === true ? id : label}`) : label}</label>}
        </div>
    );
}

export default Checkbox;