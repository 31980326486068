import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuthContext } from "../sg-react/context";
import { RouteError } from "../sg-react/ui";
import { Role } from "../sg-react/utils/enums";
import DataManagement from "./DataManagement";
import MainMap from "./MainMap";
import Private from "./Private";
import Settings from "./Settings";
import CompanyForm from "../components/CompanyForm";

const getRoutesForRole = (role: Role): RouteObject[] => [
    {
        path: "/",
        element: <Private />,
        children: [
            {
                path: '/*',
                element: <RouteError />,
            },
            {
                path: '/data-management',
                element: <DataManagement />
            },
            {
                path: '/data-management/:id',
                element: <CompanyForm />
            },
            {
                path: '/settings',
                element: <Settings />
            },
            {
                path: '/map',
                element: <MainMap />
            },
            {
                path: '/',
                element: <MainMap />
            },
        ],
    },
];


const App = () => {
    const { currentRole } = useAuthContext();

    return currentRole ? <RouterProvider router={createBrowserRouter(getRoutesForRole(currentRole))} /> : (null);
}

export default App;