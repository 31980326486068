// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  height: 100%;
}
.dropdown > .dropdown-children {
  width: 100%;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.dropdown > .dropdown-children > .dropdown-icon {
  position: absolute;
  right: 6px;
  width: 12px;
  height: 12px;
  fill: var(--theme-color-grey-medium);
}
.dropdown > .dropdown-content {
  width: 100%;
  position: absolute;
  visibility: hidden;
  display: none;
  background: var(--theme-color-white);
  border-radius: 4px;
  box-shadow: 0 3px 7px 4px rgba(122, 122, 122, 0.1);
  z-index: 52;
}
.dropdown.dropdown-visible > .dropdown-content {
  visibility: visible;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Dropdown/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ;AACI;EACI,WAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AACR;AACQ;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACZ;AAGI;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,oCAAA;EACA,kBAAA;EACA,kDAAA;EACA,WAAA;AADR;AAKQ;EACI,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,gBAAA;EACA,cAAA;AAHZ","sourcesContent":[".dropdown {\n    position: relative;\n    height: 100%;\n\n    & > .dropdown-children {\n        width: 100%;\n        cursor: pointer;\n        height: 100%;\n        position: relative;\n\n        & > .dropdown-icon {\n            position: absolute;\n            right: 6px;\n            width: 12px;\n            height: 12px;\n            fill: var(--theme-color-grey-medium);\n        }\n    }\n\n    & > .dropdown-content {\n        width: 100%;\n        position: absolute;\n        visibility: hidden;\n        display: none;\n        background: var(--theme-color-white);\n        border-radius: 4px;\n        box-shadow: 0 3px 7px 4px rgb(122, 122, 122, 0.1);\n        z-index: 52;\n    }\n\n    &.dropdown-visible {\n        & > .dropdown-content {\n            visibility: visible;\n            width: fit-content;\n            overflow: hidden;\n            display: block;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
