// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#company-form {
  display: flex;
  flex-direction: column;
}
#company-form .company-form-contact, #company-form .company-form-alert {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}
#company-form .company-form-contact > .input, #company-form .company-form-alert > .input {
  margin: 0;
  flex-grow: 1;
}
#company-form .company-form-contact > svg, #company-form .company-form-alert > svg {
  margin: 0 12px;
  fill: var(--theme-color-error);
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompanyForm/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EAEI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,WAAA;AAAR;AAEQ;EACI,SAAA;EACA,YAAA;AAAZ;AAGQ;EACI,cAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;AADZ","sourcesContent":["#company-form {\n    display: flex;\n    flex-direction: column;\n\n    & .company-form-contact,\n    & .company-form-alert {\n        display: flex;\n        align-items: center;\n        gap: 4px;\n        width: 100%;\n\n        & > .input {\n            margin: 0;\n            flex-grow: 1;\n        }\n\n        & > svg {\n            margin: 0 12px;\n            fill: var(--theme-color-error);\n            width: 20px;\n            height: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
