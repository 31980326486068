import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Company, isCompany } from '../../models/company';
import { Qualifier, isQualifier } from '../../models/qualifier';
import { useMapContext } from '../../sg-react/context';
import { MapFilters } from '../../sg-react/context/MapContext';
import { useDebounce, useOutsideClick, useRequest } from '../../sg-react/hooks';
import { CloseIcon, FactoryIcon, SearchGlobeIcon, SearchGlobeWireIcon, TreeIcon, TypingIcon, WindTurbines } from '../../sg-react/icons';
import { Card, Dropdown, Menu, MenuItem, SpannedText } from '../../sg-react/ui';
import './index.scss';

interface HeaderSearchProps {

}

const HeaderSearch = ({ }: HeaderSearchProps) => {
    const { filters, setFilters, withSourcing } = useMapContext();
    const [keyword, setKeyword] = useState<string | undefined>();
    const [selectedResult, setSelectedResult] = useState<string | undefined>();
    const [isResultsPanelVisible, setResultsPanelVisible] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [tempMode, setTempMode] = useState<'internal' | 'sourcing'>(filters.mode);
    const [results, setResults] = useState<(Company | Qualifier)[] | null>(null);
    const request = useRequest();
    useDebounce(() => getResults(keyword), 500, [keyword]);
    const ref = useOutsideClick(() => setResultsPanelVisible(false));

    const handleKeyword = useCallback((k: string) => {
        setKeyword(k);

        if (!!k && k.length >= 3) {
            setLoading(true);
        } else {
            setResults(null);
            setLoading(false);
        }
    }, []);

    const handleFiltersChange = useCallback((filters: MapFilters, selectedResult: string) => {
        setFilters({ ...filters, fetch: true });
        setSelectedResult(selectedResult);
        setResultsPanelVisible(false);
    }, [setFilters]);

    const clearSearch = useCallback(() => {
        setKeyword('');
        setResults(null);
        setSelectedResult('');
    }, []);

    const handleChangeMode = useCallback((m: 'internal' | 'sourcing') => {
        setTempMode(m);
        setResults(null);
        setSelectedResult('');
    }, [clearSearch]);

    const getResults = useCallback(async (k?: string) => {
        if (!k || k.length < 3) return;
        setLoading(true);

        request.get<(Company | Qualifier)[]>(tempMode === 'internal' ? '/directory/global-search' : '/directory/global-search', { params: { keyword: k }, i18n: true, errorMessage: 'error.server_error' })
            .then(setResults)
            .catch(() => null)
            .finally(() => setLoading(false));

    }, [tempMode]);

    useEffect(() => {
        setTempMode(filters.mode);
        clearSearch();
    }, [filters.mode])

    const resultsComponents = useMemo(() => (results ?? []).map(r => {
        if (isCompany(r)) {
            return (
                <div key={r.id} className="header-search-result" onClick={() => handleFiltersChange({ mode: tempMode, companies: [r.id] }, r.name)}>
                    {r.qualifiers?.some(q => q.id === 1807) ? <WindTurbines /> : <FactoryIcon />}
                    <div>
                        <span className="header-search-result-name"><SpannedText text={r.name} boldedWords={keyword ?? ''} /></span>
                        {!!r?.address && <span className="header-search-result-description">{r.address}</span>}
                    </div>
                </div>
            );
        } else if (isQualifier(r)) {
            return (
                <div key={r.id} className="header-search-result" onClick={() => handleFiltersChange({ mode: tempMode, qualifiers: [r.id] }, r.name)}>
                    <TreeIcon />
                    <div>
                        <span className="header-search-result-name">
                            {filters.mode === 'sourcing' && r.root && <small>{r.root?.name} : </small>}
                            <SpannedText text={r.name} boldedWords={keyword ?? ''} />
                        </span>
                        {!!r?.description && <span className="header-search-result-description"><SpannedText text={r.description} boldedWords={keyword ?? ''} /></span>}
                    </div>
                </div>
            );
        }

        return null;
    }), [results, filters, handleFiltersChange]);

    return (
        <div id="header-search" ref={ref}>
            {withSourcing && (
                <div id="header-search-switch">
                    <Dropdown withIcon closeOnClick dropdown={
                        <Menu>
                            <MenuItem icon={<SearchGlobeWireIcon />} label="Données internes" onClick={() => handleChangeMode('internal')} />
                            <MenuItem icon={<SearchGlobeIcon />} label="Sourcing" onClick={() => handleChangeMode('sourcing')} />
                        </Menu>
                    }>
                        {tempMode === 'internal' ? <SearchGlobeWireIcon /> : <SearchGlobeIcon />}
                    </Dropdown>
                </div>
            )}
            <input
                type="text"
                id="search"
                placeholder="Rechercher un fournisseur, un secteur..."
                value={!isResultsPanelVisible && !!selectedResult ? selectedResult : keyword ?? ''}
                onChange={(e) => !selectedResult || e.target.value !== selectedResult ? handleKeyword(e.target.value) : null}
                onFocus={() => setResultsPanelVisible(true)}
            />
            {!!keyword && <CloseIcon onClick={clearSearch} id="header-search-clear" />}
            {isResultsPanelVisible && !!keyword && keyword.length > 3 && (
                <Card id="header-search-results">
                    <div className="header-search-result" onClick={() => handleFiltersChange({ mode: tempMode, keyword }, keyword)}>
                        <TypingIcon />
                        <div>
                            <span className="header-search-result-name"><span>{keyword}</span></span>
                        </div>
                    </div>
                    {resultsComponents}
                </Card>
            )}
        </div>
    )
}

export default HeaderSearch;