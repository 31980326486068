// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 24px;
  background-color: var(--theme-container-background);
  border: var(--theme-container-border) 1px solid;
  box-shadow: var(--theme-container-shadow);
}
.card .card-title {
  align-items: center;
  font-size: 24px;
  color: var(--theme-container-title-color);
  margin-bottom: 18px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Card/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,mDAAA;EACA,+CAAA;EACA,yCAAA;AACJ;AACI;EACI,mBAAA;EACA,eAAA;EACA,yCAAA;EACA,mBAAA;AACR","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n    border-radius: 4px;\n    padding: 24px;\n    background-color: var(--theme-container-background);\n    border: var(--theme-container-border) 1px solid;\n    box-shadow: var(--theme-container-shadow);\n\n    & .card-title {\n        align-items: center;\n        font-size: 24px;\n        color: var(--theme-container-title-color);\n        margin-bottom: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
