// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 11px;
  color: var(--theme-font-color-main);
}
.tooltip > .tooltip-tooltip {
  position: fixed;
  z-index: 100000;
  background-color: var(--theme-menu-background);
  padding: 4px 8px;
  border-radius: 4px;
  border: var(--theme-menu-border) 1px solid;
  font-size: 11px;
  max-height: 250px;
  max-width: 400px;
  overflow-y: auto;
  font-family: Montserrat;
  line-height: 12px;
  visibility: hidden;
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Tooltip/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,eAAA;EACA,mCAAA;AACJ;AACI;EACI,eAAA;EACA,eAAA;EACA,8CAAA;EACA,gBAAA;EACA,kBAAA;EACA,0CAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EAEA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;AAAR","sourcesContent":[".tooltip {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-decoration: none;\n    font-size: 11px;\n    color: var(--theme-font-color-main);\n\n    & > .tooltip-tooltip {\n        position: fixed;\n        z-index: 100000;\n        background-color: var(--theme-menu-background);\n        padding: 4px 8px;\n        border-radius: 4px;\n        border: var(--theme-menu-border) 1px solid;\n        font-size: 11px;\n        max-height: 250px;\n        max-width: 400px;\n        overflow-y: auto;\n\n        font-family: Montserrat;\n        line-height: 12px;\n        visibility: hidden;\n        white-space: pre-line;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
