import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import '../styles/index.scss';
import { Spinner } from "../ui";
import Toast, { ToastData } from "../ui/Toast";
import { ToastType } from "../utils/enums";

interface UiProviderProps {
    children: ReactNode;
    theme?: string;
    darkMode?: boolean;
}

export type UIContextType = {
    setToast: (toast: ToastData) => void,
    setToastError: (message: string) => void,
    setToastSuccess: (message: string) => void,
    isNavToggled: boolean,
    toggleNav: () => void,
    setNavToggled: (toggled: boolean) => void,
    setSpinnerVisible: (_isSpinnerVisible: boolean) => void,
    isSpinnerVisible: boolean,
    isDarkMode: boolean,
};

export const UiContext = createContext<UIContextType>({
    setToast: (_) => null,
    setToastError: (_) => null,
    setToastSuccess: (_) => null,
    isNavToggled: false,
    toggleNav: () => null,
    setNavToggled: (_) => null,
    setSpinnerVisible: (_) => null,
    isSpinnerVisible: false,
    isDarkMode: false,
});

const UiProvider = ({ children, theme, darkMode }: UiProviderProps) => {
    const [isNavToggled, setNavToggled] = useState<boolean>(false);
    const [isSpinnerVisible, setSpinnerVisible] = useState<boolean>(false);
    const [toast, setToast] = useState<ToastData | null>(null);

    const toggleNav = () => setNavToggled(!isNavToggled);
    const setToastError = useCallback((message: string) => setToast({ type: ToastType.Error, message }), []);
    const setToastSuccess = useCallback((message: string) => setToast({ type: ToastType.Success, message }), []);


    const handleCloseToast = async () => {
        /*         if (error) {
                    setErrorToNotify({
                        loggingId: error.loggingId,
                        error: error.loggingId ? JSON.stringify(error, null, 2) : error.toString()
                    });
                }
         */
        setToast(null);
    }

    return (
        <UiContext.Provider value={{
            setToast,
            setToastError,
            setToastSuccess,
            isNavToggled,
            setNavToggled,
            toggleNav,
            setSpinnerVisible,
            isSpinnerVisible,
            isDarkMode: !!darkMode
        }}>
            <div id="app" className={`theme-${theme} theme-${darkMode ? 'dark' : 'light'}`}>
                {children}
                <Toast toast={toast} onClose={handleCloseToast} />
                <div id="spinner" className={!!isSpinnerVisible ? 'visible' : ''}>
                    <Spinner />
                </div>
            </div>
        </UiContext.Provider>
    );
};

const useUiContext = () => {
    return useContext(UiContext);
};

export { UiProvider, useUiContext };
export default UiContext;