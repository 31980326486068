import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { Marker } from 'react-map-gl';
import { Company } from '../../models/company';
import { DEPENDENCY_SIMPLE_LIST_SCHEMA, DEPENDENCY_VALIDATION, Dependency } from '../../models/dependency';
import { Map, SimpleList } from '../../sg-react/data';
import { LocationPicker, TextArea, TextField } from '../../sg-react/form';
import { useForm, useRequest } from '../../sg-react/hooks';
import { WindTurbine } from '../../sg-react/icons';
import { Modal } from '../../sg-react/ui';
import './index.scss';

type WindTurbineDependency = Dependency & {
    constructorName: string;
    power: number;
    height: number;
}

interface DependencyFormProps {
    directory: Partial<Company>;
    onChange: (e: Dependency[]) => void;
}

const DependenciesForm = ({ directory, onChange }: DependencyFormProps) => {
    const [dependencyIndexToEdit, setDependencyIndexToEdit] = useState<number | null>(null);
    const request = useRequest();
    const { entity, reset, attachInput, validate, onMultipleChange } = useForm<WindTurbineDependency>({});

    const markers = useMemo(() => directory?.dependencies?.filter(d => d.lat && d.lng).map(d => (
        <Marker
            key={`marker-dependency-${d.id ?? d.name}`}
            longitude={d.lng}
            latitude={d.lat}
            anchor="center"
        >
            <div className="marker-dependency">
                <WindTurbine />
            </div>
        </Marker>
    )), [directory]);

    const handleAdd = useCallback(() => {
        reset({
            lng: directory.lng,
            lat: directory.lat,
            streetNumber: directory.streetNumber,
            route: directory.route,
            city: directory.city,
            region: directory.region,
            country: directory.country,
            postalCode: directory.postalCode,
            address: directory.address,
            directoryId: directory.id,
        });
        setDependencyIndexToEdit(-1);
    }, [reset, directory]);

    const handleEdit = useCallback((e: Dependency, index: number) => {
        reset({
            ...e,
            ...e.additionalData
        } as WindTurbineDependency);
        setDependencyIndexToEdit(index);
    }, [reset]);

    const handleSubmit = useCallback(async () => {
        if (!validate(DEPENDENCY_VALIDATION) || dependencyIndexToEdit === null) {
            return;
        }

        entity.directoryId = directory.id;

        const _dependencies = [...(directory.dependencies ?? [])];

        if (dependencyIndexToEdit === -1) {
            _dependencies.push(entity as Dependency);
        } else {
            _dependencies[dependencyIndexToEdit] = entity as Dependency;
        }
        onChange(_dependencies);
        setDependencyIndexToEdit(null);
        /* 
                const requestMethod = isNew ? request.post : request.put;
                const successMessage = isNew ? 'success.create' : 'success.update';
        
                requestMethod<Dependency>(
                    '/dependencies',
                    {
                        ...entity,
                        additionalData: {
                            constructorName: entity.constructorName,
                            power: entity.power,
                            height: entity.height,
                        }
                    },
                    {
                        i18n: true,
                        loader: true,
                        successMessage,
                        errorMessage: 'error.server_error'
                    })
                    .then((dependency) => {
                        const _dependencies = [...(directory.dependencies ?? [])];
                        const index = _dependencies.findIndex((d) => d.id === dependency.id);
        
                        if (index === -1) {
                            _dependencies.push(dependency);
                        } else {
                            _dependencies[index] = dependency;
                        }
                        onChange(_dependencies);
                        setDepencyFormModalVisible(false);
                    })
                    .catch(() => null); */
    }, [validate, directory, entity, onChange, dependencyIndexToEdit]);

    return (
        <div className="dependencies-form">
            <SimpleList<Dependency>
                data={directory.dependencies}
                primaryKey="id"
                schema={DEPENDENCY_SIMPLE_LIST_SCHEMA}
                actions={{
                    add: handleAdd,
                    edit: handleEdit,
                }}
            />
            <Map
                markers={markers}
                fitToMarkers
            />
            {dependencyIndexToEdit !== null && (
                <Modal
                    type="small"
                    header={entity.id
                        ? t('data:edit')
                        : t('data:new')
                    }
                    onClose={() => setDependencyIndexToEdit(null)}
                    onSubmit={handleSubmit}
                >
                    <TextField small label i18n="dependencies" {...attachInput('name')} />
                    <TextArea small label i18n="dependencies" rows={5} {...attachInput('description')} />
                    <LocationPicker small entity={entity} onMultipleChange={onMultipleChange} />
                    <TextField small label i18n="dependencies" {...attachInput('modele')} />
                    <TextField small label i18n="dependencies" {...attachInput('constructeur')} />
                    <TextField small label type="number" i18n="dependencies" {...attachInput('puissance')} />
                    <TextField small label type="number" i18n="dependencies" {...attachInput('hauteur_max_mat')} />
                </Modal>
            )
            }
        </div>
    )
}

export default DependenciesForm;