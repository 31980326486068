import { useTranslation } from "react-i18next";
import CompanyCard from "../../components/CompanyCard";
import CompanyForm from "../../components/CompanyForm";
import { COMPANY_LIST_SCHEMA, COMPANY_VALIDATION, Company } from "../../models/company";
import { DataCardList, DataList } from "../../sg-react/data";
import { Breadcrumbs } from "../../sg-react/ui";
import './index.scss';
import { FilterType } from "../../sg-react/data/FiltersPanel";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../sg-react/ui/PageHeader";

const DataManagement = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div id="data-management">
            <PageHeader
                breadcrumbs={[{ href: '/data-management', label: 'path:data-management', i18n: true }]}
                actions={[
                    { label: 'actions:create', i18n: true, onClick: () => navigate('/data-management/create'), color: 'primary' }
                ]}
            />
            <DataCardList<Company>
                primaryKey="id"
                endpoint="/directory"
                onClick={(e) => navigate(`/data-management/${e.id}`)}
                filters={[
                    { field: 'name', label: t('companies:name'), type: FilterType.Search },
                    { field: 'city', label: t('companies:city'), type: FilterType.Ilike },
                    { field: 'region', label: t('companies:region'), type: FilterType.Ilike },
                    { field: 'country', label: t('companies:country'), type: FilterType.Ilike },
                    { field: 'qualifier', label: t('companies:sector'), type: FilterType.Tree, endpoint: '/qualifiers/tree/1800' },
                    { field: 'qualifier', type: FilterType.Null },
                ]}
                columns={[
                    { field: 'name', label: 'companies:name', i18n: true, sort: true, width: 20 },
                    { field: 'address', label: 'companies:address', i18n: true, sort: true, width: 20 },
                    { field: 'country', label: 'companies:country', i18n: true, sort: true, width: 20 },
                    {
                        field: 'sector',
                        label: 'companies:sector',
                        i18n: true,
                        sort: true,
                        width: 40,
                        display: (e) =>
                            e.qualifiers
                                ?.filter((q) => q.rootId === 1800)
                                .map((q) => q.name)
                                .join(", "),
                    },
                ]}
                initialPagination={{ sortBy: 'name' }}
            />
        </div>
    );
}

export default DataManagement;