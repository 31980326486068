// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.map > .mapboxgl-map {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/Map/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;AACR","sourcesContent":[".map {\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n\n    & > .mapboxgl-map {\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
