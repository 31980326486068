// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-small-list {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  font-family: Montserrat;
}
.company-small-list > * {
  display: flex;
  flex-direction: column;
  padding: 2px 12px;
  width: 100%;
  background-color: var(--theme-data-item-background);
  overflow: hidden;
}
.company-small-list > * > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
}
.company-small-list > * > span:first-child {
  font-size: 12px;
}
.company-small-list > * > span:last-child {
  font-size: 10px;
  color: var(--theme-color-grey-medium);
}
.company-small-list > *:nth-child(even) {
  background-color: var(--theme-data-item-background-even);
}`, "",{"version":3,"sources":["webpack://./src/components/CompanySmallList/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,WAAA;EACA,mDAAA;EACA,gBAAA;AACR;AACQ;EACI,mBAAA;EACA,uBAAA;EACA,iBAAA;AACZ;AACY;EACI,eAAA;AAChB;AAEY;EACI,eAAA;EACA,qCAAA;AAAhB;AAIQ;EACI,wDAAA;AAFZ","sourcesContent":[".company-small-list {\n    display: flex;\n    flex-direction: column;\n    max-width: 300px;\n    font-family: Montserrat;\n\n    & > * {\n        display: flex;\n        flex-direction: column;\n        padding: 2px 12px;\n        width: 100%;\n        background-color: var(--theme-data-item-background);\n        overflow: hidden;\n\n        & > span {\n            white-space: nowrap;\n            text-overflow: ellipsis;\n            line-height: 16px;\n\n            &:first-child {\n                font-size: 12px;\n            }\n\n            &:last-child {\n                font-size: 10px;\n                color: var(--theme-color-grey-medium);\n            }\n        }\n\n        &:nth-child(even) {\n            background-color: var(--theme-data-item-background-even);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
