import { Qualifier } from '../../models/qualifier';
import Tree from '../../sg-react/data/Tree';
import { TreeIcon, UsersIcon } from '../../sg-react/icons';
import LayoutLeftTabs, { LayoutLeftTabsSection } from '../../sg-react/ui/LayoutLeftTabs';
import PageHeader from '../../sg-react/ui/PageHeader';
import './index.scss';

const ITEMS = [
    { key: 'sector', label: 'Secteurs', icon: <TreeIcon /> },
    { key: 'users', label: 'Utilisateurs', icon: <UsersIcon /> }
];

const Settings = () => {
    return (
        <div id="settings">
            <PageHeader breadcrumbs={[{ href: '/settings', label: 'path:settings', i18n: true }]} />
            <LayoutLeftTabs items={ITEMS}>
                <LayoutLeftTabsSection item={ITEMS[0]}>
                    <Tree<Qualifier>
                        endpoint="/qualifiers/tree/1800"
                        crudEndpoint="/qualifiers"
                        reverseMapper={(d) => ({ id: Number(d.key), name: d.name, description: !!d.description ? d.description : undefined, parentId: Number(d.parent), rootId: 1800 })}
                        edit
                        add
                    />
                </LayoutLeftTabsSection>
                <LayoutLeftTabsSection item={ITEMS[1]}>
                    En construction
                </LayoutLeftTabsSection>
            </LayoutLeftTabs>
        </div>
    )
}

export default Settings;