import { getNestedField } from "./../utils/objects";
import { useCallback, useState } from "react";
import { changeFieldValue } from "../utils/objects";

export type Validation = {
    rule: ValidationRule;
    compareTo?: any;
    error?: string;
};

export enum ValidationRule {
    Required,
    NotEmpty,
    MinLength,
    MaxLength,
    Integer,
    PositiveInteger,
    Number,
    PositiveNumber,
    Email,
    Regex,
    Equals,
    EqualsField,
    Url,
}

const validateOne = (
    value: any,
    rule: ValidationRule,
    compareTo?: any,
    entity?: any
): string | null => {
    switch (rule) {
        case ValidationRule.Required:
            return !!value || value === 0 ? null : "required";
        case ValidationRule.NotEmpty:
            return Array.isArray(value) && !!value?.length ? null : "not_empty";
        case ValidationRule.MinLength:
            return (value?.length ?? 0) >= compareTo ? null : "min_length";
        case ValidationRule.MaxLength:
            return (value?.length ?? 0) <= compareTo ? null : "max_length";
        case ValidationRule.Integer:
            return value === undefined ||
                (!isNaN(value) && Number.isInteger(value))
                ? null
                : "integer";
        case ValidationRule.PositiveInteger:
            return value === undefined ||
                (!isNaN(value) && Number.isInteger(Number(value)) && value > 0)
                ? null
                : "positive_integer";
        case ValidationRule.Number:
            return value === undefined || !isNaN(value) ? null : "numeric";
        case ValidationRule.PositiveNumber:
            return value === undefined || (!isNaN(value) && value > 0)
                ? null
                : "positive_numeric";
        case ValidationRule.Email:
            const reEmail =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !value || reEmail.test(value) ? null : "email";
        case ValidationRule.Url:
            const reURL =
                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
            return !value || reURL.test(value) ? null : "url";
        case ValidationRule.Regex:
            return !value || String(value).match(compareTo) ? null : "regex";
        case ValidationRule.Equals:
            return !value || value === compareTo ? null : "equals";
        case ValidationRule.EqualsField:
            return !value || value === entity[compareTo]
                ? null
                : "equals-field";
        default:
            return null;
    }
};

export const validateField = (
    entity: any,
    field: string,
    validations: Validation[]
): string[] => {
    return validations
        .map((v) => {
            const error = validateOne(
                entity[field],
                v.rule,
                v.compareTo,
                v.compareTo ? entity : undefined
            );
            return error;
        })
        .filter((e) => !!e) as string[];
};

export const validateEntity = <T>(
    entity: Partial<T>,
    validations?: FormValidation
): [boolean, Record<string, string[]>] => {
    let isValid = true;
    const errors: Record<string, string[]> = {};

    for (const field in validations) {
        const fieldValidations: Validation[] = validations[field] ?? [];

        const fieldErrors = validateField(entity, field, fieldValidations);

        if (fieldErrors.length) {
            isValid = false;
            errors[field] = fieldErrors;
        }
    }

    return [isValid, errors];
};

export type FormValidation = Record<string, Validation[]>;

export type FormErrors = Record<string, string[]>;

export interface FormHookReturn<T> {
    entity: Partial<T>;
    hasChanged: boolean;
    attachInput: (field: string) => any;
    onChange: (field: string, value: any) => void;
    onMultipleChange: (changes: Record<string, any>) => void;
    setEntity: (_entity: Partial<T>) => void;
    reset: (_entity?: Partial<T>) => void;
    validate: (validations?: FormValidation) => boolean;
    setErrors: (e: FormErrors) => void;
    setWarnings: (e: FormErrors) => void;
    errors: FormErrors;
    warnings: FormErrors;
}

export const useForm = <T>(
    initialEntity: Partial<T> | undefined
): FormHookReturn<T> => {
    const [cleanEntity, setCleanEntity] = useState<Partial<T>>({
        ...initialEntity,
    });
    const [entity, setEntity] = useState<Partial<T>>({ ...initialEntity });
    const [errors, setErrors] = useState<FormErrors>({});
    const [warnings, setWarnings] = useState<FormErrors>({});
    const [hasChanged, setChanged] = useState<boolean>(false);

    const onChange = useCallback((field: string, value: any): void => {
        setEntity((entity) => {
            setErrors((errors) => ({ ...errors, [field]: [] }));
            setChanged(true);

            return changeFieldValue(entity, field, value);
        });
    }, []);

    const onMultipleChange = useCallback(
        (changes: Record<string, any>): void => {
            setEntity((entity) => {
                if (!Object.keys(changes).length) return entity;

                let _entity = { ...entity };
                let hasChanged = false;

                for (const key in changes) {
                    _entity = changeFieldValue(_entity, key, changes[key]);
                    hasChanged = true;
                }

                setErrors((errors) => {
                    const _errors = { ...errors };

                    for (const key in changes) {
                        _errors[key] = [];
                    }
                    return _errors;
                });

                if (hasChanged) {
                    setChanged(true);
                    return _entity;
                }
                return entity;
            });
        },
        []
    );

    const reset = useCallback(
        (_entity?: Partial<T>) => {
            if (_entity) {
                setCleanEntity(_entity);
                setEntity(_entity);
            } else {
                setEntity(cleanEntity);
            }
            setChanged(false);
            setErrors({});
        },
        [cleanEntity]
    );

    const validate = useCallback(
        (validations?: FormValidation): boolean => {
            const [isValid, _errors] = validateEntity(entity, validations);
            setErrors(_errors);

            return isValid;
        },
        [entity]
    );

    const attachInput = useCallback(
        (field: string) => ({
            id: field,
            onChange: (v: any) => onChange(field, v),
            value: getNestedField(entity, field),
            errors: errors[field],
            entity,
            onMultipleChange,
        }),
        [entity, errors, onChange, onMultipleChange]
    );

    return {
        entity,
        hasChanged,
        reset,
        attachInput,
        onChange,
        onMultipleChange,
        validate,
        setEntity,
        setErrors,
        setWarnings,
        errors,
        warnings,
    };
};

export default useForm;
