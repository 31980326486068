import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRequest } from "../../hooks";
import { DownIcon, UpIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Nothing } from "../../ui";
import Button, { ButtonColor, ButtonProps } from "../../ui/Button";
import { filtersFromSearchParams, getStringValue, paginationFromSearchParams } from "../../utils/format";
import { getPrimaryKeysAsString } from "../../utils/objects";
import FiltersPanel, { ListFilterSetting } from "../FiltersPanel";
import PaginationComponent from "../Pagination";
import "./index.scss";

export interface DataCardListContentProps<T> {
    entity: T;
};

export interface DataCardListAction<T> {
    color: ButtonColor;
    label: string;
    icon?: ReactNode;
    onClick: (e: T) => Promise<void | boolean> | void | boolean;
}

export interface DataCardListGlobalAction {
    color: ButtonColor;
    label: string;
    icon?: ReactNode;
    onClick: () => Promise<void | boolean> | void | boolean;
}

export interface DataCardListLink<T> {
    label: string;
    icon?: ReactNode;
    onClick: (e: T) => void | Promise<void>;
}

export type DataCardListColumn<T> = {
    field: string;
    label: string;
    i18n?: boolean;
    display?: ((e: T) => JSX.Element | string | undefined);
    width?: number;
    sort?: boolean;
}
export type DataCardListColumnWithWidth<T> = (DataCardListColumn<T> & { widthRatio: string });

export interface DataCardListProps<T> {
    columns: DataCardListColumn<T>[];
    primaryKey: keyof T | (keyof T)[];
    filters?: ListFilterSetting[];
    endpoint: string;
    actions?: DataCardListAction<T>[];
    globalActions?: ButtonProps[];
    initialPagination?: Partial<Pagination>;
    additionalFilters?: { [k: string]: any };
    onClick?: (e: T) => void;
}

const DataCardList = <T,>({
    columns: columnsFromProps,
    filters,
    primaryKey,
    endpoint,
    actions,
    globalActions,
    initialPagination,
    additionalFilters,
    onClick
}: DataCardListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const [pagination, setPagination] = useState<Partial<Pagination> | null>(null);
    const [columns, setColumns] = useState<DataCardListColumnWithWidth<T>[]>([]);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const get = useCallback(async (searchParams: URLSearchParams) => {
        if (isLoading) return;

        const params = {
            ...initialPagination,
            ...filtersFromSearchParams(searchParams, filters),
            ...paginationFromSearchParams(searchParams)
        }
        setLoading(true);
        request.get<PaginatedResults<T>>(endpoint, { params, errorMessage: 'error.server_error', i18n: true, loader: true })
            .then((result) => {
                if (!Array.isArray(result?.data)) {
                    throw new Error();
                }
                setData(result.data);
                setPagination(result.pagination);
                if (tableRef.current) {
                    tableRef.current.scrollTo(0, 0);
                }
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [isLoading, pagination, endpoint, additionalFilters, initialPagination, filters]);

    const onPageChange = useCallback((page: number) => {
        searchParams.set('page', String(page));
        setSearchParams(searchParams, { replace: true });
    }, [searchParams]);

    const onPerPageChange = useCallback((perPage: number) => {
        searchParams.set('perPage', String(perPage));
        searchParams.delete('page');
        setSearchParams(searchParams, { replace: true });
    }, [searchParams]);

    const onSort = useCallback((sortBy: string) => {
        searchParams.set('sortBy', sortBy);
        searchParams.set('sortOrder', String(pagination?.sortBy !== sortBy || pagination.sortOrder === -1 ? 1 : -1));
        setSearchParams(searchParams, { replace: true });
    }, [searchParams, pagination]);

    const columnHeaders = useMemo(() => columns.map((column) => (
        <div
            key={column.field}
            className={`data-card-list-column-header ${column.sort ? 'sortable' : ''} ${column.field === pagination?.sortBy ? 'sorted' : ''}`}
            style={{ width: column.widthRatio }}
            onClick={() => onSort(column.field)}
        >
            <span>{column.i18n ? t(column.label) : column.label}</span>
            {column.sort && pagination?.sortBy === column.field && pagination.sortOrder === -1 && <UpIcon />}
            {column.sort && pagination?.sortBy === column.field && pagination.sortOrder === 1 && <DownIcon />}
        </div>
    )), [columns, onSort, pagination]);

    const rows = useMemo(() => !data.length && !isLoading
        ? <Nothing />
        : data.map(entity => (
            <div
                className={`data-card-list-row ${onClick ? 'clickable' : ''}`}
                key={getPrimaryKeysAsString(entity, primaryKey)}
                onClick={onClick ? () => onClick(entity) : undefined}
            >
                {columns.map(column => (
                    <div
                        key={column.field}
                        style={{ width: column.widthRatio }}
                    >
                        <span>{column.display ? column.display(entity) : getStringValue(entity[column.field as keyof T])}</span>
                    </div>
                ))}
            </div>
        ))
        , [data, primaryKey, columns, onClick, isLoading]);

    const globalActionsComponents = useMemo(() =>
        !!globalActions?.length && globalActions.map((a) => (
            <Button key={a.label} {...a} />
        ))
        , [globalActions]);


    useEffect(() => {
        if (tableRef.current) {
            const _columns: DataCardListColumnWithWidth<T>[] = [];
            const totalWidth = columnsFromProps.reduce((w, column) => w + (column.width ?? (100 / columnsFromProps.length)), 0);

            for (const column of columnsFromProps) {
                const columnWidth = column.width ?? (100 / columnsFromProps.length);

                _columns.push({ ...column, widthRatio: (columnWidth * 100 / totalWidth) + '%' });
            }

            setColumns(_columns);
        }
    }, [columnsFromProps]);

    useEffect(() => {
        get(searchParams);
    }, [searchParams, endpoint]);

    return (
        <div className="data-card-list">
            {!!filters?.length && <FiltersPanel filtersSettings={filters} />}
            <div className="data-card-list-container">
                {!!globalActions?.length && (
                    <div className="data-card-list-header">
                        <div className="data-card-list-sort">
                        </div>
                        <div className="data-card-list-actions">
                            {globalActionsComponents}
                        </div>
                    </div>
                )}
                <div className="data-card-list-table" ref={tableRef}>
                    <div className="data-card-list-table-header">
                        {columnHeaders}
                    </div>
                    {rows}
                </div>
                <PaginationComponent pagination={pagination ?? undefined} onPageChange={onPageChange} />
            </div >
        </div>
    )
}

export default DataCardList;