// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: -60px;
  left: 0;
  z-index: 110;
  transition: bottom 0.5s ease-in-out;
}
.toast > div {
  max-width: 800px;
  padding: 8px 32px;
  border-radius: 4px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
  font-size: 13px;
  color: var(--theme-color-grey-lightest);
}
.toast > div > svg {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  fill: var(--theme-color-grey-lightest);
}
.toast > div > span:first-letter {
  text-transform: capitalize;
}
.toast.toast-active {
  bottom: 30px;
}
.toast.toast-active > div {
  opacity: 1;
}
.toast.toast-success > div {
  background-color: var(--theme-color-success);
}
.toast.toast-error > div {
  background-color: var(--theme-color-error);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Toast/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,OAAA;EACA,YAAA;EACA,mCAAA;AACJ;AACI;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,oCAAA;EACA,eAAA;EACA,eAAA;EACA,uCAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,sCAAA;AACZ;AAEQ;EACI,0BAAA;AAAZ;AAII;EACI,YAAA;AAFR;AAIQ;EACI,UAAA;AAFZ;AAMI;EACI,4CAAA;AAJR;AAMI;EACI,0CAAA;AAJR","sourcesContent":[".toast {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    bottom: -60px;\n    left: 0;\n    z-index: 110;\n    transition: bottom 0.5s ease-in-out;\n\n    & > div {\n        max-width: 800px;\n        padding: 8px 32px;\n        border-radius: 4px;\n        opacity: 0;\n        justify-content: center;\n        align-items: center;\n        display: flex;\n        transition: opacity 0.5s ease-in-out;\n        cursor: pointer;\n        font-size: 13px;\n        color: var(--theme-color-grey-lightest);\n\n        & > svg {\n            width: 14px;\n            height: 14px;\n            margin-right: 8px;\n            fill: var(--theme-color-grey-lightest);\n        }\n\n        & > span:first-letter {\n            text-transform: capitalize;\n        }\n    }\n\n    &.toast-active {\n        bottom: 30px;\n\n        & > div {\n            opacity: 1;\n        }\n    }\n\n    &.toast-success > div {\n        background-color: var(--theme-color-success);\n    }\n    &.toast-error > div {\n        background-color: var(--theme-color-error);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
