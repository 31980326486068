import { ReactNode, useMemo } from "react";
import { DataListColumnWithWidth } from ".";
import { Select, TextField } from "../../form";
import { getStringValue } from "../../utils/format";

interface CellProps<T> {
    entity: T;
    id: string;
    column: DataListColumnWithWidth<T>;
    onChange: (value: any) => void;
    errors?: string[];
}

const Cell = <T,>({ entity, id, column, onChange, errors }: CellProps<T>) => {
    const v = useMemo(() => {

        let v: ReactNode;
        let stringV = getStringValue(entity[id as keyof T]);

        if (typeof column.display === 'function') {
            v = column.display(entity);
        } else if (column.options?.edit) {
            if (column.display === 'textinput') {
                v = (
                    <TextField
                        id={id as string}
                        onChange={onChange}
                        value={stringV}
                        errors={errors}
                    />
                )
            } else if ((column.display === 'select-multiple' || column.display === 'select') && column.values) {
                v = (
                    <Select
                        id={id as string}
                        value={Array.isArray(entity[id as keyof T]) ? entity[id as keyof T] as string[] : entity[id as keyof T] as string}
                        multiple={column.display === 'select-multiple'}
                        items={column.values.map(v => ({ key: stringV, label: stringV })) ?? []}
                        onChange={onChange}
                        errors={errors}
                    />
                )
            }
        } else {
            v = <span>{stringV}</span>;
        }

        return v;
    }, [column, entity, errors]);

    return (
        <div
            className="data-list-table-cell"
            style={{ width: column.widthPx + 'px', minWidth: column.widthPx + 'px' }}
        >
            {v}
        </div>
    )
}

export default Cell;