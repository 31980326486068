import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../context';
import { Role } from '../../utils/enums';
import './index.scss';
import Button, { ButtonProps } from '../Button';

export interface LayoutLeftTabsItem {
    key: string;
    label?: string;
    icon?: ReactNode;
    roles?: Role[];
}

interface LayoutLeftTabsSectionProps {
    item: LayoutLeftTabsItem;
    id?: string;
    className?: string;
    actions?: ButtonProps[];
    children: ReactNode;
}

export const LayoutLeftTabsSection = ({ item, className, id, children, actions }: LayoutLeftTabsSectionProps) => (
    <div left-tab-key={item.key} className="layout-left-tabs-section">
        <div className="layout-left-tabs-section-title">
            {item.icon}
            <span>{item.label}</span>
            <div className="layout-left-tabs-section-actions">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
        <div className={`layout-left-tabs-section-content ${className ?? ''}`} id={id}>
            {children}
        </div>
    </div>
)

interface LayoutLeftTabsProps {
    items: LayoutLeftTabsItem[];
    i18n?: boolean;
    children: ReactNode;
    id?: string;
    className?: string;
}

const LayoutLeftTabs = ({ items, i18n, children, id, className }: LayoutLeftTabsProps) => {
    const { currentUser, currentRole } = useAuthContext();
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null);

    const goTo = useCallback((key: string) => {
        if (!contentRef.current) return;

        contentRef.current.querySelector<HTMLDivElement>(`div[left-tab-key="${key}"]`)?.scrollIntoView()
    }, [items]);

    const tabs = useMemo(() => items
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => (
            <div
                key={item.key}
                className="layout-left-tabs-tab"
                onClick={() => goTo(item.key)}
            >
                <span>{i18n ? t('path:' + item.key) : item.label ?? item.key}</span>
            </div>
        )), [items, currentRole, currentUser, i18n, goTo])

    return (
        <div className={`layout-left-tabs ${className ?? ''}`} id={id}>
            <div className="layout-left-tabs-tabs">
                {tabs}
            </div>
            <div className="layout-left-tabs-content" ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

export default LayoutLeftTabs;