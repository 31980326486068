// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse {
  display: flex;
  flex-direction: column;
  border-top: var(--theme-soft-container-border) 1px solid;
  border-bottom: var(--theme-soft-container-border) 1px solid;
  flex-shrink: 0;
}
.collapse > .collapse-header {
  padding: 6px 24px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  flex-shrink: 0;
  text-align: left;
  color: var(--theme-soft-container-title-color);
  cursor: pointer;
}
.collapse > .collapse-header > div {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.collapse > .collapse-header > div > .collapse-subtitle {
  margin-left: 5px;
  font-weight: normal;
}
.collapse > .collapse-header > svg {
  width: 12px;
  height: 12px;
  fill: var(--theme-soft-container-title-color);
}
.collapse > .collapse-children {
  width: 100%;
  max-height: 0;
  display: flex;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.collapse > .collapse-children.opened {
  border-top: var(--theme-soft-container-border) 1px solid;
  max-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Collapse/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,wDAAA;EACA,2DAAA;EACA,cAAA;AACJ;AACI;EACI,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,8CAAA;EACA,eAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AACZ;AACY;EACI,gBAAA;EACA,mBAAA;AAChB;AAGQ;EACI,WAAA;EACA,YAAA;EACA,6CAAA;AADZ;AAKI;EACI,WAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,uCAAA;AAHR;AAKQ;EACI,wDAAA;EACA,iBAAA;AAHZ","sourcesContent":[".collapse {\n    display: flex;\n    flex-direction: column;\n    border-top: var(--theme-soft-container-border) 1px solid;\n    border-bottom: var(--theme-soft-container-border) 1px solid;\n    flex-shrink: 0;\n\n    & > .collapse-header {\n        padding: 6px 24px;\n        height: 40px;\n        display: flex;\n        align-items: center;\n        justify-content: flex-start;\n        font-size: 12px;\n        flex-shrink: 0;\n        text-align: left;\n        color: var(--theme-soft-container-title-color);\n        cursor: pointer;\n\n        & > div {\n            display: flex;\n            flex-direction: row;\n            flex-grow: 1;\n\n            & > .collapse-subtitle {\n                margin-left: 5px;\n                font-weight: normal;\n            }\n        }\n\n        & > svg {\n            width: 12px;\n            height: 12px;\n            fill: var(--theme-soft-container-title-color);\n        }\n    }\n\n    & > .collapse-children {\n        width: 100%;\n        max-height: 0;\n        display: flex;\n        overflow: hidden;\n        transition: max-height 0.3s ease-in-out;\n\n        &.opened {\n            border-top: var(--theme-soft-container-border) 1px solid;\n            max-height: 100vh;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
