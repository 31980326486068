import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Toggle } from "../../form";
import { useDebounce, useRequest } from "../../hooks";
import { CloseIcon, PencilIcon, PlusIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Button, Nothing } from "../../ui";
import { getStringValue } from "../../utils/format";
import { getNestedField, getPrimaryKeysAsString, isType, primaryKeysEquals, toggleInArray } from "../../utils/objects";
import PaginationComponent from "../Pagination";
import "./index.scss";

export interface SimpleListSchemaColumn<T> {
    field: string;
    label?: string;
    display?: 'toggle' | 'checkbox' | ((e: T) => ReactNode);
}

export interface ListProps<T> {
    schema: SimpleListSchemaColumn<T>[];
    i18n?: string;
    primaryKey: keyof T | (keyof T)[];
    data?: T[];
    endpoint?: string;
    actions?: {
        create?: () => void;
        add?: () => void;
        delete?: (e: T, index: number) => Promise<void | boolean> | void | boolean;
        deleteMultiple?: (e: T[], indexes: number[]) => Promise<void | boolean> | void | boolean;
        click?: (e: T, index: number) => Promise<void | boolean> | void | boolean;
        change?: (e: T, index: number) => Promise<void | boolean> | void | boolean;
        edit?: (e: T, index: number) => Promise<void | boolean> | void | boolean;
        select?: (e: T | null, index: number) => void;
        selectMultiple?: (e: T[], indexes: number[]) => void;
    },
    withSearch?: boolean;
}

const SimpleList = <T,>({
    schema,
    i18n,
    primaryKey,
    endpoint,
    data: dataFromProps,
    actions,
    withSearch,
}: ListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [pagination, setPagination] = useState<Pagination>();
    const [search, setSearch] = useState<string>('');
    useDebounce(() => get(), 800, [search]);

    const get = useCallback(async (_pagination?: Partial<Pagination>) => {
        if (!endpoint) {
            setData(dataFromProps ?? []);
            return;
        }
        if (isLoading) return;

        setLoading(true);

        const params = {
            ...(_pagination ?? pagination),
            search: !!search ? search : undefined
        }
        request.get<PaginatedResults<T> | T[]>(endpoint, { params, i18n: true, errorMessage: 'error.server_error' })
            .then((data) => {
                if (isType<PaginatedResults<T>>(data, (o) => o.hasOwnProperty('pagination'))) {
                    setData(data.data);
                    setPagination(data.pagination);
                } else {
                    setData(data);
                }
            })
            .catch(() => null)
            .finally(() => setLoading(false));
    }, [endpoint, search, pagination, isLoading, dataFromProps]);

    const handleParamsChange = (field: keyof Pagination, value: any) => {
        get({ ...pagination, [field]: value });
    }

    const handleRowClick = useCallback(async (e: T, index: number): Promise<void> => {
        if (actions?.edit || actions?.select || actions?.selectMultiple || actions?.delete || actions?.deleteMultiple) {
            if (actions.selectMultiple || actions.deleteMultiple) {
                const _selected = toggleInArray(selected, index);
                if (actions.selectMultiple) actions.selectMultiple(data.filter((d, i) => _selected.includes(i)), _selected);
                setSelected(_selected);
            } else if (actions.edit || actions.select || actions.delete) {
                const _selected = selected.length && selected[0] === index ? null : e;
                if (actions.select) actions.select(_selected, index);
                setSelected(_selected ? [index] : []);
            }
        } else if (actions?.click) {
            const result = await actions.click(e, index);
            if (result === true) {
                get();
            }
        }
    }, [actions, selected, get, primaryKey, data]);

    const handleRowChange = useCallback(async (e: T, index: number): Promise<void> => {
        if (!actions?.change) return;

        const result = await actions.change(e, index);
        if (result === true) {
            get();
        }
    }, [actions, get]);

    const handleDelete = useCallback(async (indexes: number[]): Promise<void> => {
        if ((!actions?.delete && !actions?.deleteMultiple) || !indexes?.length) return;

        const result = actions?.delete
            ? await actions.delete(data[indexes[0]], indexes[0])
            : await actions.deleteMultiple!(data.filter((d, i) => indexes.includes(i)), indexes);

        setSelected([]);
        if (result === true) {
            get();
        }
    }, [actions, get]);

    const handleEdit = useCallback(async (e: T, index: number): Promise<void> => {
        if (!actions?.edit) return;

        const result = await actions.edit(e, index);

        setSelected([]);
        if (result === true) {
            get();
        }
    }, [actions, get]);

    const rows = useMemo(() => data.map((d, index) => {
        const cells = schema.map(column => {
            let v: ReactNode;
            if (column.display && typeof column.display === 'function') {
                v = column.display(d);
            } else if (column.display === 'toggle') {
                v = <Toggle id={column.field} value={!!d[column.field as keyof T]} onChange={() => handleRowChange({ ...d, [column.field]: !d[column.field as keyof T] }, index)} />;
            } else if (column.display === 'checkbox') {
                v = <Checkbox id={column.field} value={!!d[column.field as keyof T]} onChange={() => handleRowChange({ ...d, [column.field]: !d[column.field as keyof T] }, index)} />;
            } else {
                v = getStringValue(getNestedField(d, column.field));
            }

            return (
                <td key={column.field} className="simple-list-item">{v}</td>
            );
        });

        return (
            <tr
                key={getPrimaryKeysAsString(d, primaryKey)}
                onClick={() => handleRowClick(d, index)}
                style={(actions?.click || actions?.select || actions?.delete || actions?.selectMultiple) ? { cursor: 'pointer' } : {}}
                className={selected.includes(index) ? 'simple-list-row-selected' : ''}
            >
                {cells}
            </tr>
        );
    }
    ), [data, selected, handleRowClick, handleRowChange, actions, schema, primaryKey]);

    useEffect(() => {
        get();
    }, [endpoint, dataFromProps]);

    return (
        <div className="simple-list">
            {(!!withSearch || actions?.create || actions?.add || actions?.delete || actions?.deleteMultiple) && (
                <div className="simple-list-header">
                    {!!withSearch && (
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={t('data:search') + '...'}
                        />
                    )}
                    <div className="simple-list-actions">
                        {(!!actions?.delete || !!actions?.deleteMultiple) && !!selected?.length && <Button label={t('actions:delete')} color="error" icon={<CloseIcon />} onClick={() => handleDelete(selected)} />}
                        {!!actions?.edit && !!selected?.length && <Button label={t('actions:edit')} color="warning" icon={<PencilIcon />} onClick={() => handleEdit(data[selected[0]], selected[0])} />}
                        {!!actions?.create && <Button label={t('actions:create')} color="success" icon={<PlusIcon />} onClick={actions.create} />}
                        {!!actions?.add && <Button label={t('actions:add')} color="success" icon={<PlusIcon />} onClick={actions.add} />}
                    </div>
                </div>
            )}
            <div className="simple-list-table">
                <table>
                    <thead>
                        <tr>
                            {schema.map(column => <th key={column.field}>{i18n ? t(`${i18n}:${column.field}`) : column.label ?? ''}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                {!data?.length && !isLoading && <Nothing inline />}
            </div>
            <PaginationComponent pagination={pagination} onPageChange={(page) => handleParamsChange('page', page)} />
        </div >
    )
}

export default SimpleList;