// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.accordion > .collapse {
  width: 100%;
}
.accordion > .collapse:not(:last-child) {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Accordion/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;EACI,mBAAA;AACZ","sourcesContent":[".accordion {\n    display: flex;\n    flex-direction: column;\n    flex-shrink: 0;\n\n    & > .collapse {\n        width: 100%;\n\n        &:not(:last-child) {\n            border-bottom: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
