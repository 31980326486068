// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=select] {
  position: relative;
}
.input[input-type=select] > .input-body > .input-field > .dropdown > .dropdown-content {
  overflow-y: auto;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
.input[input-type=select] > .input-body > .input-field > .dropdown > .dropdown-content > .select-item {
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 12px;
  cursor: pointer;
  color: var(--theme-input-font-color-focus);
  background-color: var(--theme-input-background-focus);
}
.input[input-type=select] > .input-body > .input-field > .dropdown > .dropdown-content > .select-item:hover {
  background-color: var(--theme-input-background-hover);
}
.input[input-type=select] > .input-body > .input-field > .dropdown > .dropdown-content > .select-item.selected {
  background-color: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Select/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEQ;EACI,gBAAA;EACA,4BAAA;EAAA,uBAAA;AAAZ;AAEY;EACI,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;EACA,0CAAA;EACA,qDAAA;AAAhB;AAEgB;EACI,qDAAA;AAApB;AAGgB;EACI,4CAAA;AADpB","sourcesContent":[".input[input-type=\"select\"] {\n    position: relative;\n\n    & > .input-body > .input-field > .dropdown {\n        & > .dropdown-content {\n            overflow-y: auto;\n            max-height: fit-content;\n\n            & > .select-item {\n                padding: 4px 8px;\n                box-sizing: border-box;\n                font-size: 12px;\n                cursor: pointer;\n                color: var(--theme-input-font-color-focus);\n                background-color: var(--theme-input-background-focus);\n\n                &:hover {\n                    background-color: var(--theme-input-background-hover);\n                }\n\n                &.selected {\n                    background-color: var(--theme-color-success);\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
