import { useCallback, useMemo, useState } from "react";
import { Marker } from "react-map-gl";
import { Map } from "../../data";
import { GeocoderResponse } from "../../data/Map/Geocoder";
import { FormErrors } from "../../hooks/useForm";
import { LocationIcon } from "../../icons";
import { Modal } from "../../ui";
import TextField from "../TextField";
import './index.scss';

interface LocationPickerProps {
    small?: boolean;
    inline?: boolean;
    errors?: FormErrors;
    entity?: Partial<GeocoderResponse>;
    onMultipleChange: (e: Partial<GeocoderResponse>) => void;
}

const LocationPicker = ({ entity, onMultipleChange, small, inline, errors }: LocationPickerProps) => {
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [geocoderResponse, setGeocoderResponse] = useState<Partial<GeocoderResponse> | null>(null);

    const marker = useMemo(() => (geocoderResponse?.lng && geocoderResponse.lat) || (!geocoderResponse && entity?.lng && entity.lat)
        ? [
            <Marker
                key={1}
                longitude={geocoderResponse?.lng ?? entity!.lng!}
                latitude={geocoderResponse?.lat ?? entity!.lat!}
                color="#3288bd"
            />
        ]
        : []
        , [geocoderResponse, entity])

    const handleClose = useCallback(() => {
        setModalVisible(false);
        setGeocoderResponse(null);
    }, []);


    const handleSubmit = useCallback(() => {
        if (geocoderResponse) {
            onMultipleChange({
                streetNumber: geocoderResponse.streetNumber,
                route: geocoderResponse.route,
                city: geocoderResponse.city,
                region: geocoderResponse.region,
                country: geocoderResponse.country,
                postalCode: geocoderResponse.postalCode,
                lat: geocoderResponse.lat,
                lng: geocoderResponse.lng,
                address: geocoderResponse.address,
            });
        }
        handleClose();
    }, [onMultipleChange, geocoderResponse, handleClose]);

    return (
        <div className="location-picker">
            <TextField
                icon={<LocationIcon />}
                id="address"
                i18n="geo"
                label
                value={entity?.address}
                onChange={() => null}
                disabled
                onFocus={() => setModalVisible(true)}
                small={small}
                inline={inline}
                errors={errors?.address}
            />
            <div className="location-picker-location">
                <TextField
                    id="longitude"
                    i18n="geo"
                    label
                    value={String(entity?.lng ?? '')}
                    onChange={() => null}
                    disabled
                    onFocus={() => setModalVisible(true)}
                    small={small}
                    inline={inline}
                    errors={errors?.lng}
                />
                <TextField
                    id="latitude"
                    i18n="geo"
                    label
                    value={String(entity?.lat ?? '')}
                    onChange={() => null}
                    disabled
                    onFocus={() => setModalVisible(true)}
                    small={small}
                    inline={inline}
                    errors={errors?.lat}
                />
            </div>
            {!!isModalVisible && (
                <Modal onClose={handleClose} onSubmit={handleSubmit} className="location-picker-modal">
                    <TextField
                        icon={<LocationIcon />}
                        id="address"
                        i18n="geo"
                        label
                        value={geocoderResponse ? geocoderResponse.address : entity?.address}
                        onChange={() => null}
                        disabled
                        small
                    />
                    <div className="location-picker-location">
                        <TextField
                            id="longitude"
                            type="number"
                            i18n="geo"
                            label
                            value={String(geocoderResponse ? geocoderResponse?.lng ?? '' : entity?.lng ?? '')}
                            onChange={(v) => v && Number(v) < 180 && Number(v) > -180 ? setGeocoderResponse({ lng: Number(v), lat: geocoderResponse?.lat ? geocoderResponse.lat : entity?.lat }) : null}
                            small
                        />
                        <TextField
                            id="latitude"
                            type="number"
                            i18n="geo"
                            label
                            value={String(geocoderResponse ? geocoderResponse?.lat ?? '' : entity?.lat ?? '')}
                            onChange={(v) => v && Number(v) < 90 && Number(v) > -90 ? setGeocoderResponse({ lat: Number(v), lng: geocoderResponse?.lng ? geocoderResponse.lng : entity?.lng }) : null}
                            small
                        />
                    </div>
                    <Map
                        markers={marker}
                        withGeocoder
                        onGeocoderSelectResponse={setGeocoderResponse}
                        fitToMarkers
                        onMapClick={(e) => setGeocoderResponse({ lat: e.lngLat.lat, lng: e.lngLat.lng })}
                    />
                </Modal>
            )}
        </div>
    )
}

export default LocationPicker;