import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce, useOutsideClick, useRequest } from "../../hooks";
import { CircleHelpIcon, CloseIcon } from "../../icons";
import { Nothing, Spinner, Tooltip } from "../../ui";
import './index.scss';

export interface SearchResult<T> {
    key: string | number;
    label: string;
    description?: string;
    tags?: string[];
    entity?: T;
}

export interface SearchResultItem<T> extends SearchResult<T> {
    radius?: number;
}

export interface SearchResults<T> {
    count: number;
    results: SearchResult<T>[];
}

interface SearchProps<T> {
    endpoint: string;
    onClick: (e: SearchResult<T>) => void;
    onMatchClick?: (keyword: string) => void;
    placeholder?: string;
    className?: string;
    withTags?: string | boolean;
    additionalFilters?: any;
    method?: 'get' | 'post';
}

const Search = <T,>({ endpoint, onClick, placeholder, className, onMatchClick, withTags, additionalFilters, method = 'get' }: SearchProps<T>) => {
    const request = useRequest();
    const [keyword, setKeyword] = useState<string>('');
    const { t } = useTranslation();
    const [results, setResults] = useState<SearchResults<T> | null>(null);
    const [isFocused, setFocused] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    useDebounce(() => getResults(keyword), 500, [keyword]);
    const ref = useOutsideClick(() => setFocused(false));

    const handleKeyword = useCallback((k: string) => {
        setKeyword(k);

        if (!!k && k.length >= 3) {
            setLoading(true);
        } else {
            setResults(null);
            setLoading(false);
        }
    }, []);

    const clearSearch = useCallback(() => {
        setKeyword('');
        setResults(null);
        setFocused(false);
    }, []);

    const handleClick = useCallback((r: SearchResult<T>) => {
        onClick(r);
        clearSearch();
    }, [onClick, clearSearch]);

    const handleMatchClick = useCallback((k: string) => {
        if (onMatchClick) {
            onMatchClick(k);
        }
        clearSearch();
    }, [onMatchClick, clearSearch]);

    const getResults = useCallback(async (k: string) => {
        if (!k || k.length < 3) return;

        if (method === 'get') {
            request.get<SearchResults<T>>(endpoint, { params: { ...additionalFilters, keyword: k }, i18n: true, errorMessage: 'error.server_error' })
                .then(setResults)
                .catch(() => null)
                .finally(() => setLoading(false));
        } else {
            request.post<SearchResults<T>>(endpoint, { ...additionalFilters, keyword: k }, { i18n: true, errorMessage: 'error.server_error' })
                .then(setResults)
                .catch(() => null)
                .finally(() => setLoading(false));
        }

    }, [endpoint, additionalFilters, method]);

    useEffect(() => {
        clearSearch();
    }, [additionalFilters, endpoint]);

    return (
        <div className={`search ${className ?? ''}`} ref={ref}>
            <div className={`search-container ${isFocused ? 'search-container-focused' : ''}`}>
                <div className="search-input">
                    <input
                        type="text"
                        placeholder={placeholder}
                        value={keyword ?? ''}
                        onChange={(e) => handleKeyword(e.target.value)}
                        onFocus={() => setFocused(true)}
                    />
                    <div className="search-input-icons">
                        {!!isLoading && <Spinner small />}
                        <CloseIcon onClick={clearSearch} />
                        <Tooltip tooltip={t('data:search_tooltip')}>
                            <CircleHelpIcon />
                        </Tooltip>
                    </div>
                </div>
                {!!isFocused && !!results && (
                    <div className="search-results">
                        <div className="search-results-container">
                            {!!results?.count && results.results.map(r => (
                                <div key={r.key} onClick={() => handleClick(r)} className="search-result">
                                    <div className="search-result-name-description">
                                        <span>{r.label}</span>
                                        {!!r.description && <span>{r.description}</span>}
                                    </div>
                                    {!!withTags && !!r.tags?.length && (
                                        <div className="search-result-tags">
                                            {r.tags?.map(tag => typeof withTags === 'string' ? t(`${withTags}:${tag}`) : tag).join(',')}
                                        </div>)}
                                </div>
                            ))}
                        </div>
                        {!!results?.count && !!onMatchClick && (
                            <div onClick={() => handleMatchClick(keyword)} className="search-result-match">
                                ... Search {results.count} matching&nbsp;<b>{keyword}</b>
                            </div>
                        )}
                        {!!results && !results.count && !isLoading && (
                            <Nothing inline>{t('data:no_results')}</Nothing>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Search;