// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--theme-layout-border) 1px solid;
}
.page-header > .page-header-actions {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px 4px 0;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/PageHeader/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,mDAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,uBAAA;AACR","sourcesContent":[".page-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    border-bottom: var(--theme-layout-border) 1px solid;\n\n    & > .page-header-actions {\n        display: flex;\n        align-items: center;\n        gap: 6px;\n        padding: 4px 12px 4px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
