// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-nav {
  width: 100%;
  height: 48px;
  display: flex;
  padding: 0 24px;
  position: relative;
  background-color: var(--theme-nav-background);
  border-bottom: var(--theme-layout-border) 1px solid;
}
.sub-nav > .sub-nav-item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: 12px 16px;
  font-size: 13px;
  text-decoration: none;
  color: var(--theme-nav-font-color);
  gap: 8px;
}
.sub-nav > .sub-nav-item > svg {
  width: 16px;
  height: 16px;
  fill: var(--theme-nav-font-color);
}
.sub-nav > .sub-nav-item:hover, .sub-nav > .sub-nav-item.active {
  color: var(--theme-nav-font-color-hover);
}
.sub-nav > .sub-nav-item:hover > svg, .sub-nav > .sub-nav-item.active > svg {
  fill: var(--theme-nav-font-color);
}
.sub-nav > .sub-nav-item.active::after {
  position: absolute;
  top: 100%;
  height: 2px;
  background-color: var(--theme-nav-slider-color);
  content: "";
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/SubNav/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,6CAAA;EACA,mDAAA;AACJ;AACI;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,qBAAA;EACA,kCAAA;EACA,QAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,iCAAA;AACZ;AAEQ;EAEI,wCAAA;AADZ;AAGY;EACI,iCAAA;AADhB;AAKQ;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,+CAAA;EACA,WAAA;EACA,WAAA;AAHZ","sourcesContent":[".sub-nav {\n    width: 100%;\n    height: 48px;\n    display: flex;\n    padding: 0 24px;\n    position: relative;\n    background-color: var(--theme-nav-background);\n    border-bottom: var(--theme-layout-border) 1px solid;\n\n    & > .sub-nav-item {\n        position: relative;\n        height: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-grow: 0;\n        padding: 12px 16px;\n        font-size: 13px;\n        text-decoration: none;\n        color: var(--theme-nav-font-color);\n        gap: 8px;\n\n        & > svg {\n            width: 16px;\n            height: 16px;\n            fill: var(--theme-nav-font-color);\n        }\n\n        &:hover,\n        &.active {\n            color: var(--theme-nav-font-color-hover);\n\n            & > svg {\n                fill: var(--theme-nav-font-color);\n            }\n        }\n\n        &.active::after {\n            position: absolute;\n            top: 100%;\n            height: 2px;\n            background-color: var(--theme-nav-slider-color);\n            content: \"\";\n            width: 100%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
