import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Company } from "../../models/company";
import { useRequest } from "../../sg-react/hooks";
import { CloseIcon, LinkIcon, LocationIcon, PencilIcon, PhoneIcon } from "../../sg-react/icons";
import './index.scss';
import { Button } from "../../sg-react/ui";
import { dateToLocaleString } from "../../sg-react/utils/date";

interface CompanyCardProps {
    company: Company;
    onClose?: () => void;
}

const CompanyCard = ({ company: companyFromProps, onClose }: CompanyCardProps) => {
    const { t } = useTranslation();
    const request = useRequest();
    const navigate = useNavigate();
    const [company, setCompany] = useState<Company>(companyFromProps);

    const get = useCallback((id: number) => {
        request.get<Company>(`/directory/${id}`)
            .then(setCompany)
            .catch(onClose ? onClose : () => null);
    }, [onClose]);

    const handleImport = useCallback((id: number, unimport?: true) => {
        request.patch<Company>(`/directory/import/${id}${unimport ? '/unimport' : ''}`, {}, {
            i18n: true,
            loader: true,
            successMessage: 'success.update',
            errorMessage: 'error.server_error'
        })
            .then(setCompany)
            .catch(() => null);
    }, [onClose]);

    useEffect(() => {
        get(companyFromProps.id);
    }, [companyFromProps]);

    return (
        <div className="company-card">
            <div className="company-card-header">
                <div className="company-card-name">{company.name}</div>
                {!!company?.logo && <img src={company.logo} alt={company.name} />}
                {!!onClose && <CloseIcon onClick={onClose} />}
            </div>
            <div className="company-card-content">
                <div className="company-card-informations">
                    {!!company.website && <a href={company.website} target="_blank" rel="noreferrer" className="company-card-website"><LinkIcon /><span>{company.website}</span></a>}
                    {!!company.address && <div><LocationIcon /><span>{company.address}</span></div>}
                    {!!company.localPhone && <div><PhoneIcon /><span>{company.localPhone}</span></div>}
                    {!!company.internationalPhone && <div><PhoneIcon /><span>{company.internationalPhone}</span></div>}
                </div>
            </div>
            {!!company.qualifiers?.length && (
                <div className="company-card-sectors">
                    <h4>{t('companies:sector')}</h4>
                    {company.qualifiers?.map(q => (
                        <div key={q.id}>
                            <div><span>{q.root?.name} : </span>{q.name}</div>
                            <div>{q.description}</div>
                        </div>
                    ))}
                </div>
            )}
            {!!company.additionalData && (
                <div className="company-card-additional-data">
                    <h4>{t('companies:additionalData')}</h4>
                    <div><span>Altitude du parc : </span>{company.additionalData.elevation}</div>
                    <div><span>Nombre de turbines : </span>{company.additionalData.turbinesCount}</div>
                    <div><span>Mise en exploitation : </span>{company.additionalData.exploitationDate ? dateToLocaleString(company.additionalData.exploitationDate) : ''}</div>
                    <div><span>Référence de turbine : </span>{company.additionalData.turbinesReference}</div>
                    <div><span>Date repowering : </span>{company.additionalData.repoweringDate ? dateToLocaleString(company.additionalData.repoweringDate) : ''}</div>
                    <div><span>Référence de turbine pour repowering : </span>{company.additionalData.repoweringTurbinesReference}</div>
                </div>
            )}
            <Button color="primary" i18n label="actions:edit" icon={<PencilIcon />} onClick={() => navigate(`/data-management/${company.id}`)} />
        </div>
    )
}

export default CompanyCard;